import axiosInstance from "./APIClient";
import axios from "axios";

let cancel;

const getAllocationsPerformances = (callback) => {
    let url = `/portfolio/allocations/performance`

    axiosInstance.get(url)
        .then(({data}) => {
            console.debug(data)
            callback(data)
        })
        .catch((response) => {
            console.error(response);
            callback(response, true)
        })
}

const getSnapshot = (definition_ids, additional_columns, callback) => {
    let url = `/portfolio/combined`

    let params = {
        definitions: definition_ids.join(),
        recalculate: false
    }

    if (additional_columns) {
        params['additional_columns'] = additional_columns.join()
    }
    axiosInstance.get(url, {
        params: params
    })
        .then(({data}) => {
            console.debug(data)
            callback(data)
        })
        .catch((response) => {
            console.error(response);
            callback(response, true)
        })
};

const getPositions = (definition_ids, additional_columns, callback) => {
    let url = `/portfolio/combined-positions`

    let params = {definitions: definition_ids.join(),}
    if (additional_columns) {
        params['additional_columns'] = additional_columns.join()
    }

    axiosInstance.get(url, {params: params})
        .then(({data}) => {
            console.debug(data)
            callback(data)
        })
        .catch((response) => {
            console.error(response);
            callback(response, true)
        })
};

const getSupervisionSnapshot = (spv_id, additional_columns, callback) => {
    let url = `/supervision/portfolio_snapshot`

    let params = {
        id: spv_id
    }

    if (additional_columns) {
        params['additional_columns'] = additional_columns.join()
    }
    axiosInstance.get(url, {
        params: params
    })
        .then(({data}) => {
            console.debug(data)
            callback(data)
        })
        .catch((response) => {
            console.error(response);
            callback(response, true)
        })
};

const getModelsListAsync = async (searchString = null, full=false) => {
    let params = {
        full: full,
        search_string: searchString
    }
    let url = `/portfolio/model?full=false`
    return await axiosInstance.get(url, {params: params})
}

const getModels = (withPositions = true, search_string = null, modelsHandler, signal=null) => {
    let url = `/portfolio/model?full=${withPositions}`

    if (search_string) {
        url += '&search_string=' + search_string
    }
    const config = signal ? {signal: signal} : {
        cancelToken: new axios.CancelToken(function executor(c) {
            cancel = c;
        })
    }
    axiosInstance.get(url, config)
        .then(({data}) => {
            console.debug(data)
            modelsHandler(data)
        })
        .catch((response) => {
            console.error(response);
            modelsHandler(response, true)
        })
};

const getModelsOnly = (withPositions = true, search_string = null, modelsHandler) => {
    let url = `/portfolio/model?full=${withPositions}&include_benchmarks=false`

    if (search_string) {
        url += '&search_string=' + search_string
    }
    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            console.debug(data)
            modelsHandler(data)
        })
        .catch((response) => {
            console.error(response);
            modelsHandler(response, true)
        })
};

const getBenchmarkPortfoliosData = async () => {
    let url = `/portfolio/model?full=true`
    return await axiosInstance.get(url)
}

const getPortfolioFileTypes = (typesHandler) => {
    let url = `/portfolio/file_types`

    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            console.debug(data)
            typesHandler(data)
        })
        .catch(({response}) => {
            console.error(response);
        })
};

const createFromExcel = (formData, callback) => {
    let url = `/portfolio/excel`

    axiosInstance.post(url, formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const createFromFile = (formData, callback) => {
    let url = `/portfolio/from_file`

    axiosInstance.post(url, formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const fromOrion = (orion_id, type, callback) => {
    let url = `/portfolio/orion/${orion_id}`

    let data = {
        portfolio_type: type,
    }
    axiosInstance.post(url, data)
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const clone = (definition_id, type, proxy, expand_portfolio_positions, callback) => {
    let url = `/portfolio/clone/${definition_id}`

    //no proxy for now
    let data = {
        portfolio_type: type,
        expand_portfolio_positions: expand_portfolio_positions
    }
    console.log(data)
    axiosInstance.post(url, data)
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const deleteModelOrTemp = (definition_id, callback) => {
    let url = `/portfolio/${definition_id}`;
    axiosInstance.delete(url)
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const setMarketValue = (definition_id, marketValue, callback) => {
    let url;
    let lock_id = sessionStorage.getItem("clientId");
    if (lock_id) {
        url = `/portfolio/${definition_id}/market_value?lock_id=${lock_id}`;
    } else {
        url = `/portfolio/${definition_id}/market_value`;
    }
    let json_data = {
        new_market_value: marketValue,
    }
    axiosInstance.post(url, json_data)
        .then(({data}) => {
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const setName = (definition_id, new_name, callback) => {
    let url = `/portfolio/${definition_id}/name`;
    let json_data = {
        new_name: new_name,
    }
    axiosInstance.post(url, json_data)
        .then(({data}) => {
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const addNewPosition = (definition_id, position, callback) => {
    let url;
    let lock_id = sessionStorage.getItem("clientId");
    if (lock_id) {
        url = `/portfolio/${definition_id}/position?lock_id=${lock_id}`;
    } else {
        url = `/portfolio/${definition_id}/position`;
    }
    axiosInstance.post(url, position)
        .then(({data}) => {
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const updatePortfolioFromFile = (definition_id, formData, callback) => {
    let url;
    let lock_id = sessionStorage.getItem("clientId");
    if (lock_id) {
        url = `/portfolio/${definition_id}/file?lock_id=${lock_id}`;
    } else {
        url = `/portfolio/${definition_id}/file`;
    }
    axiosInstance.put(url, formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const addHistoricalCompositionFromFile = (definition_id, formData, callback) => {
    let url = `/portfolio/${definition_id}/file/historical_composition`;
    axiosInstance.put(url, formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const updateExistingPosition = (definition_id, position, callback) => {
    let url;
    let lock_id = sessionStorage.getItem("clientId");
    if (lock_id) {
        url = `/portfolio/${definition_id}/position?lock_id=${lock_id}`;
    } else {
        url = `/portfolio/${definition_id}/position`;
    }
    axiosInstance.put(url, position)
        .then(({data}) => {
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const deletePosition = (definition_id, position_indexes, callback) => {
    let url;
    let lock_id = sessionStorage.getItem("clientId");
    if (lock_id) {
        url = `/portfolio/${definition_id}/position?indexes=${position_indexes}&lock_id=${lock_id}`;
    } else {
        url = `/portfolio/${definition_id}/position?indexes=${position_indexes}`;
    }
    axiosInstance.delete(url)
        .then(({data}) => {
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const orionRefresh = (definition_id, orion_id, callback) => {
    let url;
    let lock_id = sessionStorage.getItem("clientId");
    if (lock_id) {
        url = `/portfolio/${definition_id}/orion_update/${orion_id}?lock_id=${lock_id}`;
    } else {
        url = `/portfolio/${definition_id}/orion_update/${orion_id}`;
    }
    axiosInstance.get(url)
        .then(() => {
            callback(null);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const orionRefreshMany = (orionPortfolios, callback) => {
    let url;
    let lock_id = sessionStorage.getItem("clientId");
    if (lock_id) {
        url = `/portfolio/orion_update?lock_id=${lock_id}`;
    } else {
        url = `/portfolio/orion_update`;
    }
    axiosInstance.post(url, orionPortfolios)
        .then(() => {
            callback(null);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const createEmptyPortfolio = (postData, callback) => {
    let url = `/portfolio/empty`

    axiosInstance.post(url, postData)
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const getPortfolioExcel = (portfolioId, callback) => {
    let url = `/portfolio/excel/${portfolioId}`

    axiosInstance.get(url, {
        responseType: "arraybuffer",
        cancelToken: new axios.CancelToken(function executor(c) {
            cancel = c;
        })
    })
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            console.log(error)
            callback(null, true);
        })
}

const getPortfolioExcelWithFactors = (definitionIds, additionalColumns, callback) => {
    let url = `/portfolio/excel/factors`

    let params = {
        definitions: definitionIds.join(),
        recalculate: false
    }
    if (additionalColumns) {
        params['additional_columns'] = additionalColumns.join()
    }

    axiosInstance.get(url, {
        responseType: "arraybuffer",
        params: params,
        cancelToken: new axios.CancelToken(function executor(c) {
            cancel = c;
        })
    })
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            console.log(error)
            callback(null, true);
        })
}

const getExampleExcel = (callback) => {
    let url = `/portfolio/example_excel`

    axiosInstance.get(url, {responseType: "arraybuffer"})
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            console.log(error)
            callback(null, true);
        })
}

const getExampleExcelRetail = (callback) => {
    let url = `/portfolio/example_excel_retail`

    axiosInstance.get(url, {responseType: "arraybuffer"})
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            console.log(error)
            callback(null, true);
        })
}

const getPositionsFromFile = (formData, callback) => {
    let url = `/portfolio/positions/from_file`;
    const ext = formData.get("file_name").split('.').pop();
    if(['xlsx', 'xls', 'csv'].includes(ext)) {
        url = `/portfolio/positions/from_excel_file`;
    } else {
        url = `/portfolio/positions/from_pdf_file`;
    }

    axiosInstance.post(url, formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const updatePortfolioFromPositions = (definition_id, positions, callback) => {
    let url;
    let lock_id = sessionStorage.getItem("clientId");
    if (lock_id) {
        url = `/portfolio/${definition_id}/positions?lock_id=${lock_id}`;
    } else {
        url = `/portfolio/${definition_id}/positions`;
    }
    axiosInstance.put(url, positions)
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

export
{
    getSnapshot,
    getPositions,
    getSupervisionSnapshot,
    getModels,
    getModelsListAsync,
    clone,
    fromOrion,
    deleteModelOrTemp,
    setMarketValue,
    setName,
    addNewPosition,
    updateExistingPosition,
    deletePosition,
    getPortfolioFileTypes,
    createFromExcel,
    orionRefresh,
    createEmptyPortfolio,
    orionRefreshMany,
    getPortfolioExcel,
    getExampleExcel,
    updatePortfolioFromFile,
    getAllocationsPerformances,
    addHistoricalCompositionFromFile,
    getPortfolioExcelWithFactors,
    getBenchmarkPortfoliosData,
    getModelsOnly,
    createFromFile,
    getPositionsFromFile,
    getExampleExcelRetail,
    updatePortfolioFromPositions,
    cancel
}
