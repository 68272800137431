import {getModels, getModelsListAsync} from "@API/portfolio";
import {useRequest, useToggle, useUpdateEffect} from "ahooks";
import {useState} from "react";
import {Button, Modal} from "antd";
import ModelGraph from "@components/models/graph/ModelGraph";
import {useAuth} from "@hooks/AuthHook";

const ModelGraphButton = ({graphModels = []}) => {
    const [state, {toggle, setLeft, setRight}] = useToggle();
    const {data, loading} = useRequest(() => getModelsListAsync("#", true));
    const [models, setModels] = useState([])
    const {sendGAEvent} = useAuth();

    const handleClick = () => {
        toggle();
        sendGAEvent({
            category: 'Model Portfolios',
            action: 'Click',
            label: 'Show Chart'
        });
    }

    useUpdateEffect(() => {
        if (data) {
            setModels(data.data)
        }
    }, [data])

    return <div>
        {models != [] && <Button type={"primary"} hidden={true} onClick={handleClick}>Show Chart</Button>}
        <Modal
            footer={null}
            height={800} width={800} title="Composite Models" open={state} onCancel={toggle}>
            <ModelGraph compositions={models}/>
        </Modal>
    </div>

}

export default ModelGraphButton
