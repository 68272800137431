import React from "react";
import {
    Card, Col, ConfigProvider,
    Flex,
    Popover, Row,
    Spin,
    Tag,
    theme,
    Typography
} from "antd";
import {CreditCardFilled, QuestionCircleOutlined} from "@ant-design/icons";
import NestedCard from "@components/retail/cards/NestedCard";
import {AIComment} from "@components/retail/portfolio/AnalyticsCard";


const {useToken} = theme;


const PortfolioTag = ({type,  proposalLabel="Proposed portfolio"}) => {
    const {token} = useToken();

    return <Flex gap={"small"} style={{
        // color: type === "current" ? "#3196B2" : "#e0ad60",
        fontSize: token.fontSizeSM,
        fontWeight: token.fontWeightStrong
    }}>
        <CreditCardFilled/>
        {type === "current" ? "Current portfolio" : proposalLabel}
    </Flex>
}


const AiCommentSigned = ({comment}) => {
    const {token} = useToken();

    return <Flex vertical justify={"space-between"}
                 style={{width: "100%", maxWidth: 600, flexGrow: 1}}>
        <AIComment comment={comment}/>
        <div style={{
            width: "100%",
            textAlign: "right",
            fontStyle: "italic",
            color: token.colorTextDescription,
        }}>
            — Holi
        </div>
    </Flex>
}


export const DescriptionPopover = ({children}) => {
    const {token} = useToken();

    return <ConfigProvider theme={{
        token: {
            // Style for popover
            colorText: "white",
            colorBgElevated: token.colorBgSpotlight,
            colorLink: token.colorStats,
        }
    }}>
        <Popover
            placement={"right"}
            // title={<Typography.Title level={4}>{title}</Typography.Title>}
            content={<div style={{maxWidth: 600}}>{children}</div>}
        >
            <QuestionCircleOutlined style={{marginLeft: token.marginSM}}/>
        </Popover>
    </ConfigProvider>
}


export const FundamentalsChartCard = ({
                                          type,
                                          aiComment,
                                          aiCommentProposed,
                                          aiCommentLoading,
                                          style = {},
                                          vertical = false,
                                          children
                                      }) => {
    const {token} = useToken();

    return <Card
        type={"inner"}
        style={{
            // background: token.colorBgGrey,
            background: "white",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch",
            alignItems: "stretch",
            ...style
        }}
    >
        <NestedCard
            // background={"white"}
            background={token.colorBgGrey}
        >
            {["current", "proposed"].includes(type) &&
                <PortfolioTag type={type}/>}
            {children}
        </NestedCard>
        {["current", "proposed"].includes(type)
            && <Spin spinning={aiCommentLoading}>
                <AIComment
                    comment={type === "current" ? aiComment : aiCommentProposed}/>
                <div style={{
                    width: "100%",
                    maxWidth: 600,
                    textAlign: "right",
                    fontStyle: "italic",
                    color: token.colorTextDescription
                }}>
                    — AI comment
                </div>
            </Spin>
        }
        {type === "both"
            && <Flex
                vertical={vertical}
                gap={"large"}
                // style={{marginTop: token.marginLG}}
            >
                <Flex
                    vertical
                    gap={token.marginSM}
                    style={{flexGrow: 1, flexBasis: 0}}
                >
                    <PortfolioTag type={"current"}/>
                    <Spin spinning={aiCommentLoading}>
                        <AIComment comment={aiComment}/>
                        <div style={{
                            width: "100%",
                            maxWidth: 600,
                            textAlign: "right",
                            fontStyle: "italic",
                            color: token.colorTextDescription
                        }}>
                            — AI comment
                        </div>
                    </Spin>
                </Flex>
                <Flex
                    vertical
                    gap={token.marginSM}
                    style={{flexGrow: 1, flexBasis: 0}}
                >
                    <PortfolioTag type={"proposed"}/>
                    <Spin spinning={aiCommentLoading}>
                        <AIComment comment={aiCommentProposed}/>
                        <div style={{
                            width: "100%",
                            maxWidth: 600,
                            textAlign: "right",
                            fontStyle: "italic",
                            color: token.colorTextDescription
                        }}>
                            — AI comment
                        </div>
                    </Spin>
                </Flex>
            </Flex>
        }
    </Card>
}


const FundamentalsItem = ({
                              title = null,
                              description = null,
                              vertical = false,
                              children,
                          }) => {
    const {token} = useToken();

    return <>
        {title
            && <Typography.Title level={5}>
                {title}
                <DescriptionPopover>
                    {description}
                </DescriptionPopover>
            </Typography.Title>
        }
        <Flex vertical={vertical} gap={"middle"}>
            {children}
        </Flex>
    </>
}


export const FundamentalsItemJoint = ({
                                          title = null,
                                          description = null,
                                          proposalLabel = "Proposed portfolio",
                                          chartCurrent,
                                          chartProposed,
                                          commentCurrent,
                                          commentProposed,
                                          aiCommentLoading = false,
                                          vertical = false,
                                          commonChart = false,
                                          show = "both",
                                          chartsOnly = false,
                                          style = {}
                                      }) => {
    const {token} = useToken();

    const borderColor = token.colorSplit;
    const cardPadding = `${token.padding}px ${token.paddingLG}px`;
    const chartCardBg = token.colorBgGrey;
    const commentCardBg = "white";

    let spanCurrent = 24;
    if (show === "proposed") {
        spanCurrent = 0;
    } else if (show === "both" && !vertical) {
        spanCurrent = 12;
    }

    let spanProposed = 24;
    if (show === "current" || (vertical && !commonChart)) {
        spanProposed = 0;
    } else if (show === "both" && !vertical) {
        spanProposed = 12;
    }

    return <div style={style}>
        {title
            && <Typography.Title level={5}>
                {title}
                <DescriptionPopover>
                    {description}
                </DescriptionPopover>
            </Typography.Title>
        }
        <Flex
            vertical
            style={{
                borderTop: chartsOnly ? "none" : `1px solid ${borderColor}`,
                borderBottom: chartsOnly ? "none" : `1px solid ${borderColor}`,
                marginLeft: -token.paddingLG,
                marginRight: -token.paddingLG,
            }}
        >
            <Row style={{background: chartCardBg}}>
                <Col
                    span={commonChart ? 24 : spanCurrent}
                    style={{padding: cardPadding}}
                >
                    {!commonChart && <PortfolioTag type={"current"}/>}
                    {chartCurrent}
                </Col>
                <Col
                    span={commonChart ? 0 : spanProposed}
                    style={{
                        padding: cardPadding,
                        borderLeft: (spanProposed !== 0 && !commonChart) ? `1px solid ${borderColor}` : "none"
                    }}
                >
                    <PortfolioTag type={"proposed"} proposalLabel={proposalLabel}/>
                    {spanProposed !== 0 && chartProposed}
                </Col>
            </Row>
            {!chartsOnly &&
                <Spin spinning={aiCommentLoading}>
                    <Row style={{background: commentCardBg}}>
                        <Col
                            span={spanCurrent}
                            style={{padding: cardPadding, alignSelf: "stretch"}}
                        >
                            <Flex vertical gap={"small"}
                                  style={{height: "100%"}}>
                                {commonChart &&
                                    <PortfolioTag type={"current"}/>}
                                <AiCommentSigned comment={commentCurrent}/>
                            </Flex>
                        </Col>
                        <Col
                            span={spanProposed}
                            style={{
                                padding: cardPadding,
                                alignSelf: "stretch",
                                borderLeft: (spanProposed !== 0) ? `1px solid ${borderColor}` : "none",
                            }}
                        >
                            <Flex vertical gap={"small"}
                                  style={{height: "100%"}}>
                                {commonChart &&
                                    <PortfolioTag type={"proposed"}  proposalLabel={proposalLabel}/>}
                                <AiCommentSigned comment={commentProposed}/>
                            </Flex>
                        </Col>
                    </Row>
                </Spin>
            }
            {vertical && !commonChart && (show === "both" || show === "proposed")
                && <>
                    <Flex vertical style={{
                        padding: cardPadding,
                        background: chartCardBg,
                    }}>
                        <PortfolioTag type={"proposed"} proposalLabel={proposalLabel}/>
                        {chartProposed}
                    </Flex>
                    {!chartsOnly
                        && <Spin spinning={aiCommentLoading}>
                            <Flex style={{
                                padding: `${token.padding}px ${token.paddingLG}px`,
                                background: commentCardBg,
                            }}>
                                <AiCommentSigned comment={commentProposed}/>
                            </Flex>
                        </Spin>
                    }
                </>
            }

        </Flex>
    </div>
}


export default FundamentalsItem;
