import React, {useState, useEffect, useCallback} from "react";
import {Button, Flex, message, theme, Tooltip, Typography} from "antd";
import {usePlaidLink} from "react-plaid-link";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {exchangePlaidPublicToken, getPlaidLinkToken} from "@API/clients";
import FakeProgress
    from "@components/retail/portfolio/constructor/FakeProgress";
import {CloudUploadOutlined, LinkOutlined} from "@ant-design/icons";
import {useAuth} from "@hooks/AuthHook";
import {RequestLockTooltip} from "@components/retail/cards/LockTooltip";


const {useToken} = theme;


const PlaidLink = ({
                       onClick = null,
                       buttonType = "primary",
                       withHint = false,
                       withTooltip = false,
                       style = {}
                   }) => {
    const {requestLockInfo} = useAuth();
    const {id, fetchProposal} = useHolisticoProposalContext();
    const [openProgress, setOpenProgress] = useState(false);
    const [linkToken, setLinkToken] = useState(null);
    const {token} = useToken();

    const lockInfo = requestLockInfo("#post-exchange-plaid-public-token");
    const linkLockInfo = requestLockInfo("#get-create-plaid-link-token");
    if (linkLockInfo.locked) {
        lockInfo.locked = true;
        lockInfo.until = null;
    }

    const onSuccess = (publicToken) => {
        setOpenProgress(true);
        exchangePlaidPublicToken(id, publicToken, (response, error) => {
            if (error) {
                // message.error("Error while linking account(s)");
                console.debug(error);
            } else {
                fetchProposal();
            }
            setOpenProgress(false);
        })
    };

    // Creates a Link token
    const createLinkToken = React.useCallback(async () => {
        if (!lockInfo.locked) {
            // For OAuth, use previously generated Link token
            if (window.location.href.includes("?oauth_state_id=")) {
                const _linkToken = localStorage.getItem('link_token');
                setLinkToken(_linkToken);
            } else {

                getPlaidLinkToken(id, (response, error) => {
                    if (error) {
                        // message.error("Error while creating Plaid link");
                        //error.data.message for "No investment accounts found!" error
                    } else {
                        setLinkToken(response.link_token);
                        localStorage.setItem("link_token", response.link_token);
                    }
                })
            }
        }
    }, [setLinkToken]);

    let isOauth = false;
    const config = {
        token: linkToken,
        onSuccess: onSuccess,
    };

    // For OAuth, configure the received redirect URI
    if (window.location.href.includes("?oauth_state_id=")) {
        config.receivedRedirectUri = window.location.href;
        isOauth = true;
    }
    const {open, ready} = usePlaidLink(config);

    useEffect(() => {
        if (linkToken == null) {
            createLinkToken();
        }
        if (isOauth && ready) {
            open();
        }
    }, [linkToken, isOauth, ready, open]);

    return <>
        <RequestLockTooltip
            limitMessage={"You exceeded the limit of linking accounts from your institution."}
            lockMessage={"Linking account from your institution is not available for you current subscription."}
            lockedUntil={lockInfo.until}
            locked={lockInfo.locked}
        >
            <Flex vertical gap={"small"} align={"flex-start"} style={style}>
                {withHint
                    && <Typography.Text type={"secondary"}>
                        Link investment account(s) from your institution using
                        Plaid
                    </Typography.Text>
                }
                <Tooltip
                    title={withTooltip ? " Link investment account(s) from your institution using Plaid" : null}>
                    <Button
                        type={buttonType}
                        icon={<LinkOutlined/>}
                        onClick={() => {
                            if (onClick) {
                                onClick();
                            }
                            open();
                        }}
                        disabled={!ready || lockInfo.locked}
                        size={"large"}
                    >
                        Link from institution
                    </Button>
                </Tooltip>
            </Flex>
            {/*<Flex vertical align={"flex-start"} style={{...style}}>*/}
            {/*    <Button*/}
            {/*        type={"link"}*/}
            {/*        onClick={() => {*/}
            {/*            if (onClick) {*/}
            {/*                onClick();*/}
            {/*            }*/}
            {/*            open();*/}
            {/*        }}*/}
            {/*        disabled={!ready || lockInfo.locked}*/}
            {/*        style={{padding: 0}}*/}
            {/*    >*/}
            {/*        <LinkOutlined/>*/}
            {/*        From my institution*/}
            {/*    </Button>*/}
            {/*    <Flex vertical style={{marginLeft: token.marginLG}}>*/}
            {/*        <Typography.Text type={"secondary"} style={{*/}
            {/*            // fontSize: token.fontSizeSM*/}
            {/*        }}>*/}
            {/*            Link investment account(s) from your institution using*/}
            {/*            Plaid.*/}
            {/*            /!*<br/>*!/*/}
            {/*            /!*You can choose institution and accounts you want to link.*!/*/}
            {/*            /!*Accounts composition and portfolio analytics will be updated*!/*/}
            {/*            /!*every night.*!/*/}
            {/*        </Typography.Text>*/}
            {/*        /!*<Typography.Text type={"warning"} style={{*!/*/}
            {/*        /!*    // fontSize: token.fontSizeSM*!/*/}
            {/*        /!*}}>*!/*/}
            {/*        /!*    This is a demo version of Plaid integration!*!/*/}
            {/*        /!*    Integration with some institutions may not work.*!/*/}
            {/*        /!*</Typography.Text>*!/*/}
            {/*    </Flex>*/}
            {/*</Flex>*/}
        </RequestLockTooltip>
        <FakeProgress
            tip={"Linking accounts and updating portfolio analytics..."}
            open={openProgress}
            time={60000}
        />
    </>
}

export default PlaidLink;
