import React, {useRef, useState} from 'react';
import {useRequest, useUpdateEffect} from 'ahooks';
import {get_subscription_options} from "@API/ahooks/subscriptions";
import {
    Alert,
    Card,
    Col,
    Divider,
    Flex,
    Row,
    Spin,
    Tabs,
    theme,
    Typography
} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import {RETAIL_SUBSCRIPTION_PLANS_DICT} from "@components/retail/profile/subscriptionPlans";
import SubscriptionButton from "@holistico/subscription/SubscriptionButton";


const {useToken} = theme;


const SubscriptionItem = ({
                              id,
                              title,
                              price,
                              description,
                              features,
                              recurring_interval = 'month',
                          }) => {
    const {token} = useToken();

    return <Card
        hoverable
        styles={{body: {height: "100%"}}}
        style={{height: "100%", overflow: "hidden"}}
    >
        <Flex vertical justify={"space-between"} style={{height: "100%"}}>
            <Flex vertical>
                <Typography.Text style={{
                    fontSize: token.fontSizeHeading3,
                    fontWeight: token.fontWeightStrong,
                    lineHeight: 1,
                    marginBottom: token.margin,
                    // color: token.colorStats
                }}>
                    {title}
                </Typography.Text>
                <Flex gap={"middle"} align={"flex-end"}>
                    <Typography.Text style={{
                        fontSize: token.fontSizeHeading1,
                        fontWeight: token.fontWeightStrong,
                        lineHeight: 1,
                        color: token.colorStats
                    }}>
                        ${price}
                    </Typography.Text>
                    <Typography.Text style={{marginBottom: 4,  color: token.colorStats}}>
                        / {recurring_interval}
                    </Typography.Text>
                </Flex>
                <Typography.Text style={{
                    color: token.colorTextDescription,
                    marginTop: token.margin,
                    minHeight: 80
                }}>
                    {description}
                </Typography.Text>
                <Divider/>
                <Flex vertical gap={"small"}>
                    {features.map((feature, idx) => {
                        return <Flex
                            key={idx}
                            gap={"small"}
                            align={"flex-start"} style={{
                            lineHeight: 1.2
                        }}
                        >
                            <CheckOutlined style={{color: token.colorSuccess}}/>
                            {feature}
                        </Flex>
                    })}
                </Flex>
            </Flex>
            <SubscriptionButton priceId={id} style={{
                width: "100%",
                maxWidth: 400,
                marginTop: token.marginXL,
                marginLeft: "auto",
                marginRight: "auto"
            }}/>
        </Flex>
    </Card>
}


const SubscriptionOptions = () => {
    const plansRef = useRef(null);
    const {token} = useToken();
    const {data, error, loading} = useRequest(get_subscription_options);
    const [availableSubscriptions, setAvailableSubscriptions] = useState({});

    const IntervalPrices = ({subscriptions = []}) => {
        // console.table(subscriptions);
        return (subscriptions.map((subscription) => {
            return <Col
                key={subscription.id}
                xs={24} sm={24} md={12} lg={8}
                style={{alignSelf: "stretch"}}
            >
                <SubscriptionItem
                    id={subscription.id}
                    key={subscription.id}
                    title={subscription.title}
                    price={subscription.price}
                    description={subscription.description}
                    features={subscription.features}
                    recurring_interval={subscription.recurring?.interval}
                />
            </Col>
        }))
    }

    useUpdateEffect(() => {
        let productPrices = {}
        for (let price of data.data) {

            const interval = price?.recurring?.interval ?? null;
            if (!interval) {
                continue
            } else {
                // console.log(interval)
            }
            if (!productPrices[interval]) {
                productPrices[interval] = [];
            }

            let price_product = price['product']
            let ui_product_key = price['ui_product_key']
            let ui_product_instance = RETAIL_SUBSCRIPTION_PLANS_DICT[ui_product_key]
            if (!ui_product_instance) {
                throw Error(`Product ${ui_product_key} not found.`)
            }
            ui_product_instance = {...ui_product_instance, ...price}
            ui_product_instance['title'] = price_product['name']
            ui_product_instance['description'] = price_product['description']
            productPrices[interval].push(ui_product_instance)
        }
        setAvailableSubscriptions(productPrices)
    }, [data])

    if (error) {
        return <Alert message={error} type="error"/>
    }

    return (
        <Spin spinning={loading} style={{minHeight: "300px"}}>
            <Tabs
                defaultActiveKey="month"
                centered
                items={[
                    {
                        label: `Monthly`,
                        key: 'month',
                        children: <Row gutter={[token.marginSM, token.marginSM]} ref={plansRef} style={{
                            // marginLeft: "auto",
                            // marginRight: "auto",
                            maxWidth: 1200,

                        }}>
                            <IntervalPrices key={'month'} subscriptions={availableSubscriptions['month']}/>
                        </Row>,
                    },
                    {
                        label: `Annually`,
                        key: 'year',
                        children: <Row gutter={[token.marginSM, token.marginSM]} ref={plansRef} style={{
                            // marginLeft: "auto",
                            // marginRight: "auto",
                            maxWidth: 1200,
                        }}>
                            <IntervalPrices key={'year'} subscriptions={availableSubscriptions['year']}/>
                        </Row>,
                    }
                ]}/>
        </Spin>
    )
}

export default SubscriptionOptions;
