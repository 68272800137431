import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Badge, Button, Card, Descriptions, Result, Space, theme, Typography} from "antd";
import RecentProposalsListItemMenu from "@holistico/proposal/recent/RecentProposalsListItemMenu";
import React from "react";
import {
    getAdditionalTags, ProposalCardRibbon,
    ProposalStatusBadge
} from "@global/ProposalHelper";
import CustomLegendItem from "@holistico/chart/CustomLegend";
import StatsList, {getAssetAllocationLabel} from "@holistico/proposal/new/preview/StatsList";
import {ThunderboltOutlined, UserSwitchOutlined, WalletOutlined} from "@ant-design/icons";
import ValueFormatter from "@global/ValueFormatter";
import {useAuth} from "@hooks/AuthHook";

const {useToken} = theme;

const getDateFromId = (id) => {
    return new Date(parseInt(id.substring(0, 8), 16) * 1000);
}

const {Meta} = Card;

const RecentProposalCard = ({proposal, blur, onEdit}) => {
    const {token} = useToken();
    const {user} = useAuth();
    const {sendGAEvent} = useAuth();

    const ProposalTags = () => {
        const tags = getAdditionalTags(proposal?.p_bucket_cur?.calculated_values)
        return tags
    }

    const OwnerIcon = () => {
        if (user.email === proposal['owner']) {
            if (proposal['client_type'] === 'retail_client') {
                return <Typography.Text color={token.colorError}>R</Typography.Text>
            }
            return null
        } else {
            return <Typography.Text color={"#CCCCCC"}><UserSwitchOutlined
                color={"red"}/></Typography.Text>
        }
    }

    const ProposalInnerCard = ({}) => {
        return <Card
            size={"small"}
            title={<Space direction={'vertical'} size={[token.paddingMD, 0]}
                          style={{marginTop: token.marginXS, marginBottom: token.marginSM}}>
                <Typography.Text style={{fontSize: token.fontSizeHeading4}}
                                 strong={true}>{proposal.full_name}</Typography.Text>
                <ProposalTags/>
            </Space>}
            hoverable={true}
            style={{width: "100%"}}
            extra={<RecentProposalsListItemMenu id={proposal._id} onEdit={onEdit}/>}
        >
            <div style={{paddingBottom: token.paddingSM}}>
                <ProposalStatusBadge proposal={proposal}/>
            </div>
            <Descriptions
                layout={"horizontal"}
                bordered={true}
                column={1}
                items={[
                    {
                        key: 'market_value',
                        label: 'Market value',
                        children: ValueFormatter.compactCurrency(proposal?.p_bucket_cur?.calculated_values?.market_value),
                    }, {
                        key: 'annual_dividend',
                        label: 'Distribution rate',
                        children: ValueFormatter.int_percent(proposal?.p_bucket_cur?.calculated_values?.annual_dividend),
                    }, {
                        key: 'expense_ratio',
                        label: 'Expense ratio',
                        children: ValueFormatter.int_percent(proposal?.p_bucket_cur?.calculated_values?.expense_ratio),
                    }, {
                        key: 'alloc',
                        label: 'Stocks / Bonds',
                        children: getAssetAllocationLabel(proposal?.p_bucket_cur?.calculated_values),
                    }
                ]}
            />
        </Card>
    }

    return <NavLink to={`${proposal._id}`}
                    replace={true}
                    onClick={() => {sendGAEvent({
                            category: 'Recent Proposal Card',
                            action: 'Click',
                            label: 'Open Card'
                        });}}
            >
        <ProposalCardRibbon proposal={proposal}>
            <ProposalInnerCard/>
        </ProposalCardRibbon>
    </NavLink>
}

export default RecentProposalCard;
