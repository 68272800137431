/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {
    Flex,
    Tooltip,
    Space,
    Checkbox,
    Dropdown,
    message,
    Spin,
    Typography,
    theme, Divider,
} from 'antd';
import {AppstoreFilled, HeartFilled, LoadingOutlined} from "@ant-design/icons";
import {
    addToCollection,
    getAssetCollections,
    removeFromCollection
} from "@API/screener";
import {useScreenerContext} from "@hooks/ScreenerContext";
import {capitalize} from "@global/Utils";
import "../AssetScreener.less";
import ReactGA from "react-ga4";


const {useToken} = theme;


const getAssetItemForCollection = (asset) => {
    if (['Stock', 'ADR'].includes(asset.security_type)) {
        return {
            entity: 'company', gvkey: asset.gvkey
        }
    }
    if (asset.security_type === 'ETF') {
        return {
            entity: 'etf', gvkey: asset.gvkey, iid: asset.iid
        }
    }
    if (asset.security_type === 'MF') {
        return {
            entity: 'fund', secid: asset.secid
        }
    }
    if (asset.security_type === 'Portfolio') {
        return {
            entity: 'portfolio', definition_id: asset.definition_id
        }
    }
}


const CustomCollectionButton = ({
                                    asset,
                                    collections,
                                    loading,
                                    onAddToCollection,
                                    onRemoveFromCollection
                                }) => {
    const {settings, entityGroupLabelSg} = useScreenerContext()
    const {token} = useToken();

    return <Tooltip
        title={`Click to choose ${asset.ticker ?? entityGroupLabelSg} collections`}
        mouseEnterDelay={0.5}
    >
        <Dropdown
            menu={null}
            trigger={"click"}
            overlayClassName={'screener-asset-collections-popup'}
            overlayStyle={{}}
            dropdownRender={(menu) => (
                <Spin spinning={loading} indicator={<LoadingOutlined style={{color: "rgba(0,0,0,0)"}}/>}>
                    <div style={{
                        borderRadius: token.borderRadius,
                        background: "white",
                        boxShadow: token.boxShadow,
                        padding: `${token.padding}px ${token.paddingXL}px ${token.padding}px ${token.padding}px`
                    }}>
                        <Space direction={"vertical"}>
                            <Typography.Text strong>
                                {`${asset.ticker ?? capitalize(entityGroupLabelSg)} collections`}
                            </Typography.Text>
                            <Divider style={{margin: 0}}/>
                            <Checkbox.Group
                                style={{
                                    display: "flex",
                                    flexDirection: "column"
                                }}
                                value={collections}
                            >
                                {settings.filters.collection.options.map((c) => {
                                    return <Checkbox
                                        key={c.value}
                                        value={c.value}
                                        onChange={(e) => {
                                            e.target.checked
                                                ? onAddToCollection(c.value, c.label)
                                                : onRemoveFromCollection(c.value, c.label)
                                        }}
                                    >
                                        {c.label}
                                    </Checkbox>
                                })}
                            </Checkbox.Group>
                        </Space>
                    </div>
                </Spin>)
            }
            placement={"bottomRight"}
        >
            <AppstoreFilled
                style={{fontSize: 20, color: "grey", marginRight: 4}}/>
        </Dropdown>
    </Tooltip>
}


const FavoriteCollectionButton = ({
                                      collections,
                                      onAddToCollection,
                                      onRemoveFromCollection
                                  }) => {
    const {settings} = useScreenerContext()
    const fcId = settings.favorite_collection_id

    return <Tooltip
        title={collections.includes(fcId)
            ? "Remove from favorites"
            : "Add to favorites"
        }
        mouseEnterDelay={0.5}
    >
        <Flex justify={"center"} align={"center"}
              style={{height: 24, width: 32}}>
            <HeartFilled
                onClick={() => {
                    collections.includes(fcId)
                        ? onRemoveFromCollection(fcId, "Favorites")
                        : onAddToCollection(fcId, "Favorites");
                    ReactGA.event({
                        category: 'Asset card',
                        action: 'Click',
                        label: 'Add to favorites'
                    })
                }}
                className={collections.includes(fcId)
                    ? 'screener-favorite-icon selected'
                    : 'screener-favorite-icon'
                }
            />
        </Flex>
    </Tooltip>
}

export const CardHeaderCollectionButtons = ({
                                         asset,
                                         loading,
                                         setLoading,
                                         twoLineHeader
                                     }) => {
    const {entityGroup} = useScreenerContext()
    const [collections, setCollections] = useState([])

    useEffect(() => {
        setCollections(asset.collections.map(c => c._id));
    }, [asset])

    const onAddToCollection = (collection_id, collection_name) => {
        setLoading(true)
        const item = getAssetItemForCollection(asset);
        addToCollection(entityGroup, collection_id, item, (data, error) => {
            if (error) {
                message.error(`Something wrong happened while adding ${asset.ticker} to ${collection_name} collection!`);
            }
            getAssetCollections(entityGroup, item, (data, error) => {
                if (!error) {
                    setCollections(data.map(c => c._id));
                }
                setLoading(false)
                ReactGA.event({
                    category: 'Asset card',
                    action: 'Click',
                    label: 'Add to collection'
        });
            })
        })
    }

    const onRemoveFromCollection = (collection_id, collection_name) => {
        setLoading(true)
        const item = getAssetItemForCollection(asset)
        removeFromCollection(entityGroup, collection_id, item, (data, error) => {
            if (error) {
                message.error(`Something wrong happened while removing ${asset.ticker} from ${collection_name} collection!`);
            }
            getAssetCollections(entityGroup, item, (data, error) => {
                if (!error) {
                    setCollections(data.map(c => c._id))
                }
                setLoading(false)
            })
        })
    }

    return <Flex align={"center"} style={twoLineHeader ? {} : {marginTop: 2}}>
        <CustomCollectionButton
            asset={asset}
            collections={collections}
            loading={loading}
            onAddToCollection={onAddToCollection}
            onRemoveFromCollection={onRemoveFromCollection}
        />
        <FavoriteCollectionButton
            collections={collections}
            onAddToCollection={onAddToCollection}
            onRemoveFromCollection={onRemoveFromCollection}
        />
    </Flex>
}


const CardHeader = ({
                        asset,
                        loading,
                        setLoading,
                        twoLine = false,
                    }) => {
    const textStyle = {
        fontSize: twoLine ? 20 : 24,
        fontWeight: 500,
        lineHeight: 1.2,
    }
    return <Flex justify={"space-between"} align={"flex-start"}>
        <Tooltip title={asset.name}>
            <Flex style={{width: "calc(100% - 80px)"}}>
                <span style={{...textStyle, color: "#e0ad60", marginRight: 12}}>
                    {asset.ticker}
                </span>
                <Typography.Paragraph
                    ellipsis={{rows: twoLine ? 2 : 1}}
                    style={{
                        ...textStyle,
                        marginBottom: 0,
                        height: twoLine ? 48 : "auto"
                    }}
                >
                    {asset.name}
                </Typography.Paragraph>
            </Flex>
        </Tooltip>
        <CardHeaderCollectionButtons
            asset={asset}
            loading={loading}
            setLoading={setLoading}
            twoLineHeader={twoLine}
        />
    </Flex>
}


export default CardHeader;
