import React, {useCallback, useEffect, useRef, useState} from "react";
import {Checkbox, Empty, message, Space, Spin} from 'antd';
import {useParams, useSearchParams} from "react-router-dom";
import {getClientReport, getGlobalConfigsDetails} from "@API/supervision";
import ClientInfo from "./ClientInfo";
import ClientSearch from "./ClientSearch";
import ReportConstructor from "../report_constructor/ReportConstructor";
import Localizator from "@global/Localizator";
import {SupervisionContext} from "../contexts/SupervisionContext";
import ClientActions from "@components/supervision/actions/ClientActions";
import SupervisionClientProposals from "@components/supervision/client/report_components/SupervisionClientProposals";
import ConfigSelector from "../global_configs/ConfigSelector";
import {useAuth} from "@hooks/AuthHook";
import {ClientRecommendations} from "@components/supervision/client/ClientRecommendations";


const ClientReport = () => {
    const {clientId} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const auth = useAuth();
    const [selectedClient, setSelectedClient] = useState(null);
    const [configsDetails, setConfigsDetails] = useState(null);
    const [reportLoading, setReportLoading] = useState(false);
    const [clientReport, setClientReport] = useState(null);

    const [config, setConfig] = useState(null);
    const [useClientConfig, setUseClientConfig] = useState(searchParams.get("useClientConfig") !== null ?
        searchParams.get("useClientConfig") === "true" : true);
    const abortControllerRef = useRef(new AbortController());

    useEffect(() => {
        getGlobalConfigsDetails((data, error) => {
            if (error) {
                message.error("Error while retrieving configs details")
                return
            }
            console.log(data)
            setConfigsDetails(data)
        }, abortControllerRef.current.signal);

        return () => {
            abortControllerRef.current.abort();
        }
    }, [])

    useEffect(() => {
        if (clientId) {
            setSelectedClient(clientId);
        }
    }, [clientId])

    const retrieveClientReport = useCallback(() => {
        setReportLoading(true);
        getClientReport(config.id, selectedClient, useClientConfig, (data, error) => {
            setReportLoading(false);
            if (error) {
                message.error("Error while retrieving client report");
                return
            }
            setClientReport(data);
            console.log(data)
        }, abortControllerRef.current.signal)
    }, [selectedClient, config, useClientConfig])

    useEffect(() => {
        if (selectedClient) {
            const urlParts = window.location.href.split("/");
            const lastUrlPart = urlParts[urlParts.length - 1];
            let url;
            if (lastUrlPart === "client") {
                url = window.location.href + "/" + selectedClient + window.location.search;
            } else {
                urlParts.splice(urlParts.length - 1);
                let urlBase = urlParts.join("/");
                url = urlBase + (urlBase.includes("client") ? "/" : "/client/") + selectedClient + window.location.search;
            }
            window.history.pushState({path: url}, '', url);
        }
    }, [selectedClient, config, useClientConfig])

    useEffect(() => {
        if (selectedClient !== null && config !== null && useClientConfig !== null) {
            retrieveClientReport();
        }
    }, [selectedClient, config, useClientConfig])

    const setSelectedConfigWithUrl = (conf) => {
        setConfig(conf);
        // setSearchParams(prev => {
        //     prev.set("configId", conf.id);
        //     return prev;
        // })
    }

    const setUseClientConfigWithUrl = (checked) => {
        setUseClientConfig(checked);
        setSearchParams(prev => {
            prev.set("useClientConfig", checked);
            return prev;
        })
    }

    return (
        <div className="supervision-container">
            <Space direction={'horizontal'} style={{marginBottom: 24}} size={15}>
                {auth.isSupervisor() && <div style={{width: 400}}>
                    <ClientSearch selectedClient={selectedClient} setSelectedClient={setSelectedClient}
                                  clientName={clientReport ? clientReport.name : null}/>
                </div>}
                <div style={{width: 300}}>
                    <ConfigSelector setSelectedConfig={setSelectedConfigWithUrl}/>
                </div>
                <Checkbox checked={useClientConfig} onChange={e => setUseClientConfigWithUrl(e.target.checked)}>
                    Use clients' limits
                </Checkbox>
            </Space>
            <Spin style={!clientReport ? {height: "300px"} : {}} size="large" tip="Loading client report..."
                  spinning={reportLoading}
            >
                {clientReport ? <div>
                        <SupervisionContext.Provider value={{
                            updateData: retrieveClientReport,
                            configsDetails: configsDetails
                        }}>
                            <ClientInfo client={clientReport} extra={<ClientActions
                                size={'large'}
                                showTitle={false}
                                client={{
                                    client_id: selectedClient,
                                    client_name: clientReport.name,
                                    external_id: clientReport.external_id,
                                    client_mute: clientReport.client_mute
                                }}
                                actions={['notify', 'mute_client_warn', 'analyze', 'proposal']}
                                representativeInfo={{
                                    name: clientReport.representative,
                                    email: clientReport.representative_email
                                }}
                                metricFlagData={clientReport.red_flags}/>}/>
                            <ClientRecommendations client={clientReport}/>
                            <SupervisionClientProposals proposals={clientReport.proposals}/>
                            <ReportConstructor data={clientReport.red_flags} type="client" additionalClientInfo={{
                                clientId: selectedClient,
                                clientName: clientReport.name,
                                client_mute: clientReport.client_mute
                            }} description={Localizator.t('supervision.description.client')}/>
                        </SupervisionContext.Provider>
                    </div>
                    :
                    <Empty/>}
            </Spin>
        </div>
    )
}

export default ClientReport
