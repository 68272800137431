import {Avatar, FloatButton, Layout, Menu} from 'antd';
import {
    AimOutlined,
    AppstoreAddOutlined,
    AreaChartOutlined,
    AuditOutlined,
    BlockOutlined,
    ExperimentOutlined,
    FieldTimeOutlined, FilterOutlined,
    LogoutOutlined,
    PartitionOutlined,
    ProfileOutlined,
    RadarChartOutlined,
    TeamOutlined,
    ToolOutlined,
    UploadOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Clients from "@root/site/Clients";
import SpasClient from "@components/client/SpasClient";
import Models from "@root/site/Models";
import Playground from "@root/site/Playground";
import React, {useEffect, useState} from "react";
import {useAuth} from "@hooks/AuthHook";
import Factors from "@root/site/Factors";
import AssetData from "@root/site/AssetData";
import PortfolioFactors from "@root/site/PortfolioFactors";
import Assets from "@root/site/Assets";
import packageInfo from '../package.json';

import Allocations from "@root/site/Allocations";
import AdminRoutes from "@root/site/AdminRoutes";
import Supervision from "@root/site/Supervision";
import {getHiddenHref} from "@global/Utils";
import Backtest from "@root/site/Backtest";
import {ScreenerSpasStyle} from "@root/site/Screener";

const {Content, Footer, Sider} = Layout;
const hideSider = (locationPathname) => {
    let noSiderPaths = ['/', 'proposals/enroll']
    if (noSiderPaths.includes(locationPathname)) {
        return true
    }
    return false
}
const getMenuItems = (auth) => {
    let items = [{
        key: "proposals",
        icon: <TeamOutlined/>,
        label: 'Proposals'
    }, {
        key: "playground",
        icon: <ExperimentOutlined/>,
        label: "Playground"
    }, {
        key: "screener",
        icon: <FilterOutlined/>,
        label: "Screener"
    }, {
        key: "assets",
        icon: <RadarChartOutlined/>,
        label: "Assets"
    }]

    if (auth.isHead()) {
        items.push({
            key: "models",
            icon: <AimOutlined/>,
            label: "Models"
        })
        items.push({
            key: "allocations",
            icon: <PartitionOutlined/>,
            label: "Allocations"
        })
        items.push({
            key: "backtest",
            icon: <FieldTimeOutlined/>,
            label: "Backtest"
        })

    }
    if (auth.isAdmin()) {
        items.push({
            key: "#dash",
            icon: <AreaChartOutlined/>,
            label: <a href="https://dash.signetfm.com/dash/" target="_blank" rel="noopener noreferrer">
                Dashboards
            </a>,
            outer: 'true'
        })
    }

    if (auth.isAdmin()) {
        items.push({
            key: "factors",
            icon: <AppstoreAddOutlined/>,
            label: "Factors"
        })
        items.push({
            key: "p_factors",
            icon: <ProfileOutlined/>,
            label: "Portfolio factors"
        })
    }

    if (auth.isAdmin()) {
        items.push({
            key: "data",
            icon: <UploadOutlined/>,
            label: "Data"
        })
    }

    if (auth.isSupervisor()) {
        items.push({
            key: "wealthmonitor",
            icon: <AuditOutlined/>,
            label: "Compliance"
        })
    }

    if (auth.isAdmin()) {
        items.push({
            key: "administration",
            icon: <ToolOutlined/>,
            label: "Administration"
        })
    }

    for (let item of items) {
        if (!item.outer) {
            item.label = getHiddenHref(item.label, item.key)
        }
    }

    items.push({
        type: 'divider'
    })

    items.push({
        key: "#user",
        label: auth.user?.meta?.full_name,
        className: 'signet-avatar',
        icon: <Avatar src={auth.user?.picture} size={35}
                      style={{marginLeft: -10, background: 'transparent'}}><UserOutlined/></Avatar>,

        onClick: (e) => {
            console.log('user clicked', e)
            e.domEvent.preventDefault()
        }
    })

    items.push({
        key: "#logout",
        label: "Logout",
        icon: <LogoutOutlined/>,
        onClick: () => {
            auth.logout()
        }
    })

    return items

}

const BACKTEST = "backtest"
const SPASLayout = () => {
    const [siderCollapsed, setSiderCollapsed] = useState(true);
    const [selectedKeys, setSelectedKeys] = useState(['proposals']);
    // const [notFoundUserImg, setNotFoundUserImg] = useState(false);
    const version = packageInfo.version;
    const location = useLocation()
    const navigate = useNavigate()
    const auth = useAuth();

    const onSiderCollapse = (collapsed) => {
        setSiderCollapsed(collapsed)
    };

    useEffect(() => {
        let path = location.pathname
        console.log(path)
        if (path.includes('proposal')) {
            setSelectedKeys(['proposals'])
        } else if (path.includes('administration')) {
            setSelectedKeys(['administration'])
        } else if (path.includes('supervision')) {
            setSelectedKeys(['wealthmonitor'])
        } else if (path.includes('screener')) {
            setSelectedKeys(['screener'])
        } else {
            path = location.pathname.split("/").at(-1);
            setSelectedKeys(["" + path])
        }
    }, [location.pathname])

    const onClick = (e) => {
        console.log('main menu click ', e);
        let path = location.pathname

        if (!e.key.includes('#')) {
            navigate(e.key)
        }
    };


    return (
        <Layout style={{minHeight: '100vh'}}>
            {!hideSider(location.pathname) && <Sider
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'sticky',
                    left: 0,
                    top: 0
                }}
                collapsible
                collapsed={siderCollapsed}
                onCollapse={onSiderCollapse}
                collapsedWidth={80}
            >
                <Menu theme="dark"
                      defaultSelectedKeys={['proposals']}
                      mode="inline"
                      selectedKeys={selectedKeys}
                      items={getMenuItems(auth)}
                      onClick={onClick}>
                </Menu>
            </Sider>}
            <Layout style={{paddingTop: 0}}>
                <Content style={{marginTop: "20px"}}>
                    <Routes>
                        <Route path="" element={<Navigate to="proposals" replace={true}/>}/>
                        <Route path="proposals" element={<Clients/>}/>
                        <Route path="proposal/:id/*" element={<SpasClient/>}/>
                        {auth?.isHead() && <Route path="models" element={<Models/>}/>}
                        {auth?.isHead() && <Route path="allocations" element={<Allocations/>}/>}
                        <Route exact path="playground" element={<Playground/>}/>
                        <Route path="screener/*" element={<ScreenerSpasStyle/>}/>
                        <Route path="assets" element={<Assets/>}/>
                        {auth?.isHead() &&
                            <Route path="backtest" element={<Backtest/>}/>}
                        {auth?.isAdmin() && <Route path="factors" element={<Factors/>}/>}
                        {auth?.isAdmin() &&
                            <Route path="p_factors" element={<PortfolioFactors/>}/>}
                        {auth?.isHead() && <Route path="data" element={<AssetData/>}/>}
                        {(auth?.isSales() || auth?.isSupervisor()) &&
                            <Route path="wealthmonitor/*" element={<Supervision/>}/>}
                        {auth?.isAdmin() && <Route path="administration/*" element={<AdminRoutes/>}/>}
                        <Route path="*" render={() => (<div>404</div>)}/>
                    </Routes>
                </Content>
                <FloatButton.BackTop visibilityHeight={800}/>
                <Footer style={{textAlign: 'center'}}>Signet Financial Management
                    ©{(new Date().getFullYear())}<br/><small> v. {version}</small></Footer>
            </Layout>

        </Layout>
    );
}

export default SPASLayout
