import React from "react";
import {Alert, Button, Flex, message, theme, Typography, Upload} from "antd";
import {CloudUploadOutlined} from "@ant-design/icons";
import {downloadFile} from "@global/FileDownloader";
import {
    getExampleExcelRetail,
    getPositionsFromFile
} from "@API/portfolio";
import {useAuth} from "@hooks/AuthHook";
import {
    RequestLockTooltip
} from "@components/retail/cards/LockTooltip";


const {useToken} = theme;


const FileUpload = ({addPositions, setUploading}) => {
    const {requestLockInfo} = useAuth();
    const {token} = useToken();

    const lockInfo = requestLockInfo("#post-parse-positions-from-pdf-file");

    const exampleExcelDownload = () => {
        console.log("DOWNLOAD")
        getExampleExcelRetail((response, error) => {
            if (error) {
                message.error("Something went wrong while downloading example file");
                return
            }
            let file_type = response.headers['content-type'];
            let file_name = `Portfolio example.xlsx`;
            downloadFile(response.data, file_name, file_type, () => {})
        })
    }

    const props = {
        showUploadList: false,
        multiple: true,
        maxCount: 10,
        customRequest: async ({file, onError}) => {
            console.debug('Custom request called');
            setUploading(true);
            let uploadData = new FormData();
            uploadData.append("file_content", file);
            uploadData.append("file_name", file.name);
            uploadData.append("file_type", file.type);
            uploadData.append("portfolio_name", file.name);
            uploadData.append("portfolio_type", 'temporal');

            getPositionsFromFile(uploadData, (result, error) => {
                if (error) {
                    console.error(result);
                    onError();
                } else {
                    addPositions(result);
                }
                setUploading(false);
            })
        },
    };

    return <Upload.Dragger
        {...props}
        className={"ignore-holistico"}
        style={{
            display: "flex",
            maxWidth: 360,
            background: "rgba(255, 255, 255, 0)",
            borderWidth: 2
        }}
    >
        <Flex
            align={"flex-start"}
            justify={"flex-start"}
            gap={"middle"}
            style={{height: "100%"}}
        >
            <CloudUploadOutlined style={{fontSize: 20}}/>
            <Flex vertical gap={"middle"} align={"flex-start"}>
                <Typography.Text style={{
                    color: token.colorTextDescription,
                    textAlign: "left"
                }}>
                    Upload PDF, Excel or image file
                    with portfolio positions to add
                </Typography.Text>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        exampleExcelDownload();
                    }}
                    size={"small"}
                    type={"link"}
                    style={{
                        fontSize: token.fontSizeSM,
                        padding: 0
                    }}
                >
                    File example
                </Button>
            </Flex>
        </Flex>
        {lockInfo.locked
            && <div onClick={(e) => e.stopPropagation()}>
                <RequestLockTooltip
                    limitMessage={"You exceeded the limit of uploading from pdf/image files."}
                    lockMessage={"Uploading from PDF or image files is not available for you current subscription."}
                    lockedUntil={lockInfo.until}
                    locked={true}
                >
                    <Alert
                        message={"PDF/image upload restricted!"}
                        type={"warning"}
                        style={{
                            width: "fit-content",
                            marginTop: token.margin,
                            textAlign: "left"
                        }}
                        showIcon={true}
                    />
                </RequestLockTooltip>
            </div>
        }
    </Upload.Dragger>
}


export default FileUpload;
