/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import dayjs from "dayjs";
import {
    Card,
    Col,
    Divider,
    Row,
    Spin,
    Tabs,
    Descriptions, theme
} from 'antd';
import {green, red, grey} from '@ant-design/colors';
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {useScreenerContext} from "@hooks/ScreenerContext";
import CardHeader from "@components/screener/card/CardHeader";
import StatsCard from "@components/screener/card/StatsCard";
import DescriptiveInfo from "@components/screener/card/DescriptiveInfo";
import AssetClassPie from "@components/screener/chart/AssetClassPie";
import SizeStyleHeat from "@components/screener/chart/SizeStyleHeat";
import FISectorBar from "@components/screener/chart/FISectorBar";
import CreditRatingBar from "@components/screener/chart/CreditRatingBar";
import MaturityDate from "@components/screener/chart/MaturityDate";
import RatingRadar from "@components/screener/chart/RatingRadar";
import SectorBar from "@components/screener/chart/SectorBar";
import Localizator from "@global/Localizator";
import {LockFilled} from "@ant-design/icons";
import LockTooltip, {
    LockPlaceholder
} from "@components/retail/cards/LockTooltip";
import {
    AssetAiComment,
    PeersPanel
} from "@components/screener/card/CompanyCard";


const {useToken} = theme;


export const dropNaAlloc = (a) => {
    if (!a) {
        return a;
    }
    let wSum = 0;
    const filtered = Object.keys(a)
        .filter(key => !['Not Applicable', 'Not Defined'].includes(key))
        .reduce((obj, key) => {
            obj[key] = a[key];
            wSum += a[key];
            return obj;
        }, {});
    if (wSum === 0) {
        wSum = 0.0001
    }
    Object.keys(filtered).map(key => {
        filtered[key] = filtered[key] / Math.abs(wSum);
    })

    return filtered
}


const MainPanel = ({
                       asset,
                       lockDividendYield = false,
                       lockExpenseRatio = false
                   }) => {
    return <Row gutter={[16, 16]} style={{marginBottom: 8, minHeight: 90}}>
        <Col span={6}>
            <StatsCard
                title={"Expense Ratio"}
                value={!is_null(asset.expense_ratio)
                    ? ValueFormatter.int_percent(asset.expense_ratio)
                    : "-"
                }
                valueColor={asset.expense_ratio >= 0.8
                    ? red[5]
                    : (asset.expense_ratio <= 0.2 ? green[5] : 'rgba(0, 0, 0, 0.8)')
                }
                locked={lockExpenseRatio}
            />
        </Col>
        <Col span={6}>
            <StatsCard
                title={"Distribution Rate"}
                value={asset.div_yield ? ValueFormatter.int_percent(asset.div_yield) : '-'}
                locked={lockDividendYield}
            />
        </Col>
        <Col span={12}>
            <DescriptiveInfo
                title1={
                    (asset.security_type)
                    + (
                        asset.global_broad_category_group
                            ? ", " + asset.global_broad_category_group
                            : ""
                    )
                }
                title2={asset.global_category ?? "-"}
                title3={asset.ms_institutional_category ?? "-"}
                alerts={[
                    ...(asset.short_fund ? ["Short positions"] : []),
                    ...(asset.leveraged_fund ? ["Leveraged fund"] : [])
                ]}
                style={{minHeight: 120}}
            />
        </Col>
    </Row>
}


const MetricsPanel = ({asset, lockRatings = false}) => {
    const {token} = useToken();

    return <Descriptions
        bordered
        items={[
            {
                key: 'inception-date',
                label: 'Inception',
                children: is_null(asset.inception_date)
                    ? "-" : dayjs(asset.inception_date).format("MMMM DD, YYYY"),
                span: 2
            },
            {
                key: 'fund-size',
                label: 'Fund Size',
                children: is_null(asset.fund_size)
                    ? "-" : ValueFormatter.compactCurrency(asset.fund_size),
                // span: 2
            },
            {
                key: 'ytd-return',
                label: 'YTD Return',
                children: is_null(asset.total_return_ytd)
                    ? "-" : ValueFormatter.oneDigitPercent(asset.total_return_ytd / 100),
            },
            {
                key: 'risk-score',
                label: 'Risk Score',
                children: is_null(asset.risk_score)
                    ? "-" : ValueFormatter.round(asset.risk_score, 0)
            },
            {
                key: '1y-return',
                label: '1Y Return',
                children: is_null(asset.total_return_1yr)
                    ? "-" : ValueFormatter.oneDigitPercent(asset.total_return_1yr / 100)
            },
            {
                key: 'sscore-plus',
                label: Localizator.t("factor.sscore_plus"),
                children: <>
                    {lockRatings
                        ? <LockTooltip label={"Ratings"} stuff={"data"}>
                            <LockFilled
                                style={{color: token.colorTextDescription}}/>
                        </LockTooltip>
                        : (is_null(asset.sscore_plus)
                                ? "-"
                                : ValueFormatter.round(asset.sscore_plus, 0)
                        )
                    }
                </>
            },
            {
                key: '1m-return',
                label: '1M Return',
                children: is_null(asset.total_return_1m)
                    ? "-" : ValueFormatter.oneDigitPercent(asset.total_return_1m / 100)
            },
        ]}
        size={"small"}
        column={2}
        contentStyle={{fontSize: 14, padding: "4px 8px"}}
        labelStyle={{fontSize: 14, padding: "4px 8px"}}
    />
}


const TabsPanel = ({asset, lockRatings = false, lockAllocations = false}) => {
    const {fundActiveTab} = useScreenerContext()
    const [activeKey, setActiveKey] = useState(fundActiveTab)

    useEffect(() => {
        setActiveKey(fundActiveTab)
    }, [fundActiveTab])

    const chartContainerStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: 250
    }
    const items = [
        {
            key: "ai",
            label: "Holi's comment",
            children: <div style={{minHeight: 250}}>
                 <AssetAiComment asset={asset}/>
            </div>
        },
        {
            key: 'peers',
            label: 'Peers',
            children: <div style={{minHeight: 250}}>
                <PeersPanel asset={asset}/>
            </div>
        },
        {
            key: 'asset-alloc',
            label: 'Asset allocation',
            children: <div style={chartContainerStyle}>
                {lockAllocations
                    ? <LockPlaceholder label={"Asset allocation"} stuff={"data"}/>
                    : <AssetClassPie allocation={dropNaAlloc(asset._asset_class_alloc)}/>
                }
            </div>
        },
        {
            key: 'ratings',
            label: 'Ratings',
            children: <div style={chartContainerStyle}>
                {lockRatings
                    ? <LockPlaceholder label={"Ratings"} stuff={"data"}/>
                    : <RatingRadar asset={asset}/>
                }
            </div>,
        },
        {
            key: 'sector',
            label: 'Sector',
            children: <div style={chartContainerStyle}>
                {lockAllocations
                    ? <LockPlaceholder label={"Sector"} stuff={"data"}/>
                    : <SectorBar allocation={dropNaAlloc(asset._sector_alloc)}/>
                }
            </div>,
        },
        {
            key: 'size-style',
            label: 'Size & style',
            children: <div style={chartContainerStyle}>
                {lockAllocations
                    ? <LockPlaceholder label={"Size & style"} stuff={"data"}/>
                    : <SizeStyleHeat allocation={dropNaAlloc(asset._size_style_alloc)}/>
                }
            </div>,
        },
        {
            key: 'fi-sector',
            label: 'FI Sector',
            children: <div style={chartContainerStyle}>
                {lockAllocations
                    ? <LockPlaceholder label={"FI Sector"} stuff={"data"}/>
                    : <FISectorBar allocation={dropNaAlloc(asset._fi_super_sector_alloc)}/>
                }
            </div>,
        },
        {
            key: 'credit-rating',
            label: 'Credit rating',
            children: <div style={chartContainerStyle}>
                {lockAllocations
                    ? <LockPlaceholder label={"Credit rating"} stuff={"data"}/>
                    : <CreditRatingBar allocation={dropNaAlloc(asset._fi_credit_rating_alloc)}/>
                }
            </div>
        },
        {
            key: 'maturity-date',
            label: 'Maturity date',
            children: <div style={chartContainerStyle}>
                {lockAllocations
                    ? <LockPlaceholder label={"Maturity date"} stuff={"data"}/>
                    : <MaturityDate allocation={dropNaAlloc(asset._fi_effective_maturity_alloc)}/>
                }
            </div>,
        },
    ];
    return <div style={{
        background: "#fafafa",
        margin: "12px -24px -24px -24px",
        padding: "0 24px 24px 24px",
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    }}>
        <Tabs
            activeKey={activeKey}
            onChange={(activeKey) => setActiveKey(activeKey)}
            items={items}
            style={{marginTop: 24}}
            tabBarStyle={{}}
            size={"small"}
            type={"card"}
        />
    </div>
}


const FundCard = ({
                      asset,
                      lockRatings = false,
                      lockDividendYield = false,
                      lockExpenseRatio = false,
                      lockAllocations = false
                  }) => {
    const {inScreener} = useScreenerContext();
    const [loading, setLoading] = useState(false)

    return <Card
        hoverable={inScreener}
        style={{
            borderColor: "#e3e3e3",
            overflow: "hidden",
            cursor: "default"
        }}
        className={'screener-fund-card'}
    >
        <Spin spinning={loading}>
            <CardHeader
                asset={asset}
                loading={loading}
                setLoading={setLoading}
                twoLine={true}
            />
            <Divider style={{margin: "8px 0 16px 0"}}/>
            <MainPanel
                asset={asset}
                lockDividendYield={lockDividendYield}
                lockExpenseRatio={lockExpenseRatio}
            />
            <MetricsPanel asset={asset} lockRatings={lockRatings}/>
            <TabsPanel asset={asset} lockRatings={lockRatings} lockAllocations={lockAllocations}/>
        </Spin>
    </Card>
}


export default FundCard;
