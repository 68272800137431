import axiosInstance from "@API/APIClient";


const get_all_users_emails = async (settings) => {
    let url = `/users/all-emails`
    return await axiosInstance.get(url, settings)
}


export {
    get_all_users_emails
}