export const FILTER_DISPLAY_SETTINGS = {
    company: {
        collection: {
            flexDirection: 'column',
            expanded: true
        },
        // portfolio: {
        //     flexDirection: 'row',
        //     expanded: true
        // },
        sscore_plus: {
            placeholder_left: '0',
            placeholder_right: '100',
            expanded: true,
        },
        div_yield: {
            placeholder_left: '0',
            placeholder_right: '10',
            expanded: true,
        },
        sector: {
            flexDirection: 'column',
            expanded: true
        },
        size: {
            flexDirection: 'row',
            expanded: true
        },
        vbg_style: {
            flexDirection: 'row',
            expanded: true
        },
        country: {
            flexDirection: 'column',
            expanded: true
        },
        macro_score_1q: {
            placeholder_left: '0',
            placeholder_right: '100',
            expanded: false,
        },
        macro_score_1yr: {
            placeholder_left: '0',
            placeholder_right: '100',
            expanded: false,
        },
        z_score: {
            placeholder_left: '0',
            placeholder_right: '100',
            expanded: false,
        },
        // comp_rating_ibd: {
        //     placeholder_left: '0',
        //     placeholder_right: '100',
        //     expanded: false,
        // },
        // timeliness_rank_vl: {
        //     flexDirection: 'row',
        //     expanded: false
        // },
        // cm_rank_er: {
        //     flexDirection: 'row',
        //     expanded: false
        // },
        // zacks_rank_zks: {
        //     flexDirection: 'row',
        //     expanded: false
        // },
        total_return_1yr: {
            placeholder_left: '-50',
            placeholder_right: '100',
            expanded: false
        },
        total_return_1m: {
            placeholder_left: '-50',
            placeholder_right: '100',
            expanded: false
        },
        total_return_ytd: {
            placeholder_left: '-50',
            placeholder_right: '100',
            expanded: false
        },
    },
    fund: {
        collection: {
            flexDirection: 'column',
            expanded: true
        },
        entity: {
            flexDirection: 'row',
            expanded: true
        },
        // portfolio: {
        //     flexDirection: 'column',
        //     expanded: true,
        //     showMax: 5
        // },
        expense_ratio: {
            placeholder_left: '0.0',
            placeholder_right: '0.5',
            expanded: true,
        },
        div_yield: {
            placeholder_left: '0',
            placeholder_right: '10',
            expanded: false,
        },
        risk_score: {
            placeholder_left: '0',
            placeholder_right: '100',
            expanded: false,
        },
        sscore_plus: {
            placeholder_left: '0',
            placeholder_right: '100',
            expanded: false,
        },
        global_broad_category_group: {
            flexDirection: 'row',
            expanded: true
        },
        // global_category: {
        //     flexDirection: 'column',
        //     expanded: false,
        // },
        // ms_institutional_category: {
        //     flexDirection: 'column',
        //     expanded: false,
        // },
        asset_class: {
            expanded: false,
        },
        sector: {
            expanded: false
        },
        size: {
            expanded: false
        },
        style: {
            expanded: false
        },
        fi_sector: {
            expanded: false
        },
        fi_credit_rating: {
            expanded: false
        },
        fi_maturity: {
            expanded: false
        },
        total_return_1yr: {
            placeholder_left: '-50',
            placeholder_right: '100',
            expanded: false
        },
        total_return_1m: {
            placeholder_left: '-50',
            placeholder_right: '100',
            expanded: false
        },
        total_return_ytd: {
            placeholder_left: '-50',
            placeholder_right: '100',
            expanded: false
        },
    },
    portfolio: {
        collection: {
            flexDirection: 'column',
            expanded: true
        },
        provider: {
            flexDirection: 'column',
            expanded: true
        },
        // stocks_weight: {
        //     placeholder_left: '0',
        //     placeholder_right: '100',
        //     expanded: true,
        // },
        // bonds_weight: {
        //     placeholder_left: '0',
        //     placeholder_right: '100',
        //     expanded: true,
        // },
        risk_score: {
            placeholder_left: '0',
            placeholder_right: '100',
            expanded: true,
        },
        sscore_plus: {
            placeholder_left: '0',
            placeholder_right: '100',
            expanded: true,
        },
        div_yield: {
            placeholder_left: '0',
            placeholder_right: '10',
            expanded: true,
        },
        expense_ratio: {
            placeholder_left: '0.0',
            placeholder_right: '0.5',
            expanded: true,
        },
        asset_class: {
            expanded: false
        },
        size: {
            expanded: false
        },
        style: {
            expanded: false
        },
    }
}


export const DEFAULT_ORDER_BY = {
    company: 'mkval desc nulls last',
    fund: 'fund_size desc nulls last',
    portfolio: 'risk_score asc',
}


export const CARD_TAB_OPTIONS = {
    company: [],
    fund: [
        {
            value: 'asset-alloc',
            label: 'Asset allocation',
        },
        {
            value: 'ratings',
            label: 'Ratings',
        },
        {
            value: 'sector',
            label: 'Sector',
        },
        {
            value: 'size-style',
            label: 'Size & style'
        },
        {
            value: 'fi-sector',
            label: 'FI Sector',
        },
        {
            value: 'credit-rating',
            label: 'Credit rating',
        },
        {
            value: 'maturity-date',
            label: 'Maturity date',
        },
    ],
    portfolio: [
        {
            value: 'asset-alloc',
            label: 'Asset allocation',
        },
        {
            value: 'ratings',
            label: 'Ratings',
        },
        {
            value: 'sector',
            label: 'Sector',
        },
        {
            value: 'size-style',
            label: 'Size & style'
        },
        {
            value: 'fi-sector',
            label: 'FI Sector',
        },
        {
            value: 'credit-rating',
            label: 'Credit rating',
        },
        {
            value: 'maturity-date',
            label: 'Maturity date',
        },
    ],
}