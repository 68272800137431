import React from "react";
import {Card, theme, Typography} from "antd";
import {
    AddAccountChoice,
} from "@components/retail/portfolio/AccountsCard";
import {CreditCardFilled} from "@ant-design/icons";
import SendToAdvisor from "@components/retail/portfolio/SendToAdvisor";
import CardHeader from "@components/retail/cards/CardHeader";


const {useToken} = theme;


const EnrollmentCard = ({}) => {
    const {token} = useToken();

    return <Card
        hoverable
        style={{
            borderColor: "rgb(220, 220, 220)",
            cursor: "default",
            maxWidth: 550,
            marginLeft: "auto",
            marginRight: "auto"
        }}
    >
        <CardHeader
            title={"Add account(s)"}
            style={{margin: 0}}
        />
        <Typography.Text type={"secondary"} style={{
            marginBottom: token.margin,
            // fontSize: token.fontSizeSM,
            display: "block"
        }}>
            No portfolio accounts found.
            Add accounts to get to get the analysis and AI comments.
        </Typography.Text>
        <AddAccountChoice/>
    </Card>

}

export default EnrollmentCard;
