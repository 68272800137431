import axiosInstance from "../APIClient";


const getCsvFromPositions = async (positions) => {
    let url = '/portfolio/csv-from-positions'
    return await axiosInstance.post(url, positions)
}


export {getCsvFromPositions}
