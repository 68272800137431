import React, {useMemo} from "react";
import {
    Card, Flex,
    theme,
    Typography
} from "antd";
import { WalletFilled, WalletOutlined} from "@ant-design/icons";
import SubscriptionOptions from "@holistico/subscription/SubscriptionOptions";
import CardHeader from "@components/retail/cards/CardHeader";
import NestedCard from "@components/retail/cards/NestedCard";
import {useAuth} from "@hooks/AuthHook";
import {
    RETAIL_SUBSCRIPTION_PLANS_DICT
} from "@components/retail/profile/subscriptionPlans";
import SubscriptionButton from "@holistico/subscription/SubscriptionButton";


const {useToken} = theme;


const CurrentSubscription = ({}) => {
    const {user} = useAuth();
    const {token} = useToken();

    const subscriptionInfo = useMemo(() => {
        let server_info = {}
        const current_subscription = user.current_subscription
        const stripe_info = user.stripe_info
        let trial = false
        if (current_subscription.status === 'trailing') {
            trial = true;
        }

        let ui_product_key = current_subscription['ui_product_key']
        const ui_plan = RETAIL_SUBSCRIPTION_PLANS_DICT[ui_product_key]
        const end_date = new Date(current_subscription.current_period_end * 1000);
        const formatted_date = end_date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });

        server_info = {
            ui_product_key: ui_product_key,
            trial: trial,
            status: current_subscription.status,
            balance: stripe_info.balance,
            until: formatted_date,
            title: current_subscription.plan.nickname
        }

        return server_info;
    }, [user]);

    console.log(subscriptionInfo)

    return <Card type={"inner"} style={{
        width: "fit-content",
        background: token.colorBgGrey
    }}>
        <Flex gap={"small"} align={"baseline"} wrap={"wrap"}>
            <Typography.Text style={{
                // fontSize: token.fontSizeLG
            }}>
                Current plan:
            </Typography.Text>
            <Typography.Text strong style={{
                marginRight: token.marginXL,
                // fontSize: token.fontSizeLG
            }}>
                {subscriptionInfo['title']}
            </Typography.Text>
            {subscriptionInfo?.ui_product_key !== "free"
                && <>
                    {/*<Divider type={"vertical"}/>*/}
                    <SubscriptionButton
                        buttonText={"Manage"}
                        priceId={null}
                        size={"default"}
                        type={"link"}
                        style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            marginLeft: "auto"
                        }}
                    />
                </>
            }
        </Flex>
    </Card>
}



const Subscription = ({}) => {
    const {token} = useToken();

    return <Card style={{
        cursor: "default",
        background: token.colorBgGrey,
        borderColor: "rgb(220, 220, 220)",
        maxWidth: 1200,
        marginLeft: "auto",
        marginRight: "auto",
    }}>
        <NestedCard background={"white"}>
            <CardHeader title={"Subscription"} icon={<WalletFilled/>}/>
            <CurrentSubscription/>
        </NestedCard>
        <Typography.Title level={5} style={{margin: 0}}>
            Subscription plans
        </Typography.Title>
        <SubscriptionOptions/>
    </Card>
}

export default Subscription;
