/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {
    Button,
    Checkbox,
    Form,
} from "antd";
import {is_null} from "@global/ValueFormatter";
import {useAuth} from "@hooks/AuthHook";


const ChoiceFilter = ({item, showMax=null}) => {
    const collapsible = !is_null(showMax)
    const [collapsed, setCollapsed] = useState(collapsible)
    const {sendGAEvent} = useAuth()

    return <>
        <Form.Item name={item.name}>
            <Checkbox.Group
                style={{
                    display: "flex",
                    flexDirection: item.flexDirection
                }}
                onClick={() => {
                    sendGAEvent({
                        category: 'Screener Choice Filter',
                        action: 'Click',
                        label: `Use filter on ${item.name}`
                    })
                }}
                options={collapsed
                    ?
                    item.options.filter((_, idx) => idx < showMax)
                    :
                    item.options
                }
            />
        </Form.Item>
        {collapsible && collapsed
            &&
            <Button
                size={"small"}
                type={"link"}
                onClick={() => {
                    setCollapsed(false)
                }}
                style={{marginLeft: 16, marginTop: 4}}
            >
                Show more...
            </Button>
        }
        {collapsible && !collapsed
            &&
            <Button
                size={"small"}
                type={"link"}
                onClick={() => {
                    setCollapsed(true)
                }}
                style={{marginLeft: 16, marginTop: 4}}
            >
                Show less...
            </Button>
        }
    </>
}


export default ChoiceFilter;
