import {
    App,
    Avatar,
    Flex,
    Image,
    Layout,
    Menu,
    theme,
} from "antd";
import {
    AimOutlined, AppstoreOutlined, AuditOutlined,
    BlockOutlined, FieldTimeOutlined, FilterOutlined, GiftOutlined, InfoCircleOutlined,
    LogoutOutlined, RadarChartOutlined,
    TeamOutlined,
    UserOutlined,
} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "@hooks/AuthHook";
import {getHiddenHref} from "@global/Utils";
import Constants from "@global/Constants";


const {useToken} = theme;


const urlPrefix = "/" + Constants.RIA_URL + "/";


const getMenuItems = (auth) => {

    let items = [{
        key: "dashboard",
        label: "Dashboard",
        icon: <AppstoreOutlined/>
    }, {
        key: "proposals",
        label: "Proposal",
        icon: <TeamOutlined/>
    }, {
        key: "screener",
        label: "Screener",
        icon: <FilterOutlined/>
    // }, {
    //     key: "assets",
    //     label: "Assets",
    //     icon: <RadarChartOutlined/>
    }, {
        key: "marketplace",
        label: "Models marketplace",
        icon: <GiftOutlined/>,
    }, {
        key: "models",
        label: "Models",
        icon: <AimOutlined/>
    }, {
        key: "backtest",
        label: "Backtest",
        icon: <FieldTimeOutlined/>
    }, {
        key: "compliance",
        label: "Risk Mngmt",
        icon: <AuditOutlined/>,
    }, {
        key: "tutorial",
        label: "Tutorial",
        icon: <InfoCircleOutlined/>
    }, {
        key: "#user",
        label: <Avatar src={auth.user.picture}/>,
        children: [
            {
                key: `profile/${auth.user.id}`,
                label: "Profile",
                icon: <UserOutlined/>
            },
            {
                key: `/${Constants.RETAIL_URL}`,
                label: "Individual  account",
                icon: <UserOutlined/>
            },
            {
                key: "#logout",
                label: "Logout",
                icon: <LogoutOutlined/>
            }
        ]
    }]

    if (process.env.REACT_APP_UI_VERSION !== "holistico") {
        const children = items[items.length - 1]['children']
        items[items.length - 1]['children'] = [
            ...children.slice(0, children.length - 1),
            {
                key: `/spas`,
                label: "SPAS version",
                icon: <BlockOutlined/>
            },
            children[children.length - 1]
        ]
    }

    for (let item of items) {
        if (item.key !== "#user") {
            item.label = getHiddenHref(item.label, item.key, urlPrefix)
        }
        if (!!item.children) {
            for (let child of item.children) {
                if (child.key !== "#logout") {
                    child.label = getHiddenHref(child.label, child.key, child.key.startsWith("/") ? "" : urlPrefix);
                }
            }
        }
    }

    return items
}


const HHeader = () => {
    const [selectedKey, setSelectedKey] = useState(['screener'])
    const {token} = useToken();
    const location = useLocation()
    const navigate = useNavigate()
    const auth = useAuth();

    useEffect(() => {
        let full_path = location.pathname;
        let path = full_path.split("/").at(-1);
        let key = "user"
        if (full_path.includes("dashboard")) {
            key = "dashboard"
        } else if (full_path.includes("screener")) {
            key = "screener"
        } else if (full_path.includes('compliance')) {
            key = 'compliance'
        } else if (full_path.includes('assets')) {
            key = 'assets'
        } else if (full_path.includes('screener')) {
            key = 'screener'
        } else if (full_path.includes('proposals')) {
            key = 'proposals'
        } else if (full_path.includes('marketplace')) {
            key = 'marketplace'
        } else if (full_path.includes('modals')) {
            key = 'models'
        } else if (full_path.includes('backtest')) {
            key = 'backtest'
        } else if (full_path.includes('user')) {
            key = '#user'
        } else if (full_path.includes('profile')) {
            key = '#user'
        } else {
            key = path
        }
        setSelectedKey(key)
    }, [location.pathname])

    const onClick = (e) => {
        console.log("holistico main menu click", e.key)

        if (!e.key.includes('#')) {
            if (!e.key.startsWith('/')) {
                let toNavigate = urlPrefix + e.key
                console.log(toNavigate)
                navigate(toNavigate)
            } else {
                navigate(e.key)
            }
        }

        if (e.key === "#logout") {
            auth.logout()
        }
    }

    return <App>
        <Layout.Header
            style={{
                marginBottom: token.marginMD,
                background: token.colorWhite,
                boxShadow: '0 4px 60px rgba(0,0,0,.1)',
                padding: 0
            }}
        >
            <Flex
                justify={'space-between'}
                align={'flex-start'}
                gap={16}
                style={{
                    margin: "0 auto",
                    width: "94%",
                    maxWidth: 1500
                }}
            >
                <NavLink to={`/${Constants.RIA_URL}`}>
                    <Image
                        src={"/img/logo-holistico-for-white-bg.png"}
                        preview={false}
                        height={20}
                        width={137}
                    />
                </NavLink>
                <div style={{width: "calc(100% - 200px)"}}>
                    <Menu
                        mode="horizontal"
                        selectedKeys={[selectedKey]}
                        onClick={onClick}
                        items={getMenuItems(auth)}
                        style={{justifyContent: 'flex-end'}}
                    />
                </div>
            </Flex>
        </Layout.Header>
    </App>
}

export default HHeader
