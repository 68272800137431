import React, {useEffect, useMemo, useRef, useState} from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import {useUpdateEffect} from "ahooks";
import {
    List,
    Flex,
    theme,
} from "antd";
import {useAiChatContext} from "@hooks/AiChatContext";
import ChatMessage from "@holistico/ai/chat/AiChatMessage";
import AiChatInput from "@holistico/ai/chat/AiChatInput";
import AiChatHints from "@holistico/ai/chat/AiChatHints";


const {useToken} = theme;



export const AiChat = ({height = "45vh"}) => {
    const scrollToRef = useRef(null);
    const containerRef = useRef(null);
    const {
        messageList,
        newMsgTrigger,
        setNewMsgTrigger,
    } = useAiChatContext()

    let scrollToIndex = useMemo(() => {
        if (!messageList || messageList.length === 0) {
            return -1;
        }
        let idx = messageList.length;
        while (idx--) {
            if (messageList[idx].role === "user")
                 return idx;
        }
    }, [messageList, newMsgTrigger]);

    useEffect(() => {
        setNewMsgTrigger((cur) => cur + 1);
    }, []);

    useUpdateEffect(() => {
        setTimeout(() => {
            if (scrollToRef?.current && containerRef?.current) {
                scrollIntoView(
                    scrollToRef.current,
                    {
                        block: 'start',
                        behavior: 'smooth',
                        boundary: containerRef.current
                    }
                )
            }
        }, 500)
    }, [newMsgTrigger])

    return <Flex vertical ref={containerRef} style={{
        background: "white",
        position: "relative"
    }}>
        <List
            gutter={24}
            size={'small'}
            dataSource={messageList}
            renderItem={(item, index) => {
                return <>
                    {index === scrollToIndex && <div ref={scrollToRef}/>}
                    <ChatMessage
                        key={index}
                        content={item.content}
                        role={item.role}
                    />
                </>
            }}
            style={{
                width: '100%',
                height: height,
                overflowY: 'scroll',
            }}
        >
            {scrollToIndex === -1 && <div ref={scrollToRef}/>}
            <AiChatHints/>
        </List>
        <AiChatInput/>
    </Flex>
}


export default AiChat;
