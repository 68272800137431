/* eslint-disable react/prop-types */
import React from 'react';
import {Form, Select, Flex} from "antd";
import {useScreenerContext} from "@hooks/ScreenerContext";
import {useAuth} from "@hooks/AuthHook";


const SortSelect = ({}) => {
    const {settings, entityGroup} = useScreenerContext();
    const {isDataLocked} = useAuth();

    let dividendYieldLocked = false
    let expenseRatioLocked = false;
    let ratingsLocked = false;
    if (['company', 'fund'].includes(entityGroup)) {
        if (isDataLocked("#security-dividend-yield"))
            dividendYieldLocked = true;
        if (isDataLocked("#security-expense-ratio"))
            expenseRatioLocked = true;
        if (isDataLocked("#security-ratings"))
            ratingsLocked = true;
    }
    if (entityGroup === 'portfolio') {
        if (isDataLocked("#model-dividend-yield"))
            dividendYieldLocked = true;
        if (isDataLocked("#model-expense-ratio"))
            expenseRatioLocked = true;
        if (isDataLocked("#model-ratings"))
            ratingsLocked = true;
    }

    return <Flex align={"center"}>
        <span style={{
            marginRight: "10px",
            fontWeight: 500,
            whiteSpace: "nowrap",
            fontSize: 18
        }}>
            Sort by:
        </span>
        <Form.Item
            name="order_by"
            colon={true}
            style={{width: "240px"}}
        >
            <Select options={settings.order_by.options.filter(option => !option.additional).map(item => {
                let locked = false;
                if (dividendYieldLocked && item.value.startsWith("div_yield")){
                    locked = true;
                }
                if (expenseRatioLocked && item.value.startsWith("expense_ratio")){
                    locked = true;
                }
                if (ratingsLocked && item.value.startsWith("sscore_plus")){
                    locked = true;
                }

                return {value: item.value, label: item.label, disabled: locked}
            })}/>
        </Form.Item>
    </Flex>
}


export default SortSelect;
