import React, {useState} from "react";
import {
    Col,
    Row,
    Affix,
    theme,
    Grid,
    FloatButton,
    Flex,
    Card,
    Typography,
    Button
} from "antd";
import {
    EditOutlined,
    FullscreenOutlined,
    MinusOutlined
} from "@ant-design/icons";
import {AiChatContextProvider} from "@hooks/AiChatContext";
import AiChat from "@holistico/ai/chat/AiChat";
import Notes from "@holistico/ai/notes/Notes";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import {
    HoliAvatar,
    HoliFloatButton
} from "@components/retail/portfolio/AiChatPanel";


const {useToken} = theme;
const {useBreakpoint} = Grid;


const AiChatCard = ({
                        height = "45vh",
                        setAiMinimized = null,
                        setAiExpand = null,
                    }) => {
    const {token} = useToken();

    return <Card
        hoverable
        style={{
            borderColor: "rgb(220, 220, 220)",
            cursor: "default",
            overflow: "hidden",
        }}
    >
        <div style={{margin: -token.marginLG}}>
            <Flex
                justify={"space-between"}
                style={{
                    borderBottom: "1px solid rgb(220, 220, 220)",
                    padding: token.paddingSM
                }}
            >
                <Flex gap={"small"} align={"center"}>
                    <HoliAvatar size={"small"}/>
                    <Typography.Title level={5} style={{margin: 0}}>
                        Holi
                    </Typography.Title>
                </Flex>
                <Flex align={"center"}>
                    <Button
                        type="text"
                        icon={<MinusOutlined/>}
                        onClick={() => setAiMinimized && setAiMinimized(true)}
                    />
                    <Button
                        type="text"
                        icon={<FullscreenOutlined/>}
                        onClick={() => setAiExpand && setAiExpand(true)}
                    />
                </Flex>
            </Flex>
            <AiChat height={height}/>
        </div>
    </Card>
}


const NotesCard = ({
                       setNotesMinimized = null,
                       setNotesExpand = null,
                       height = "auto",
                       autoSize = false
                   }) => {
    const {token} = useToken();

    return <Card
        hoverable
        style={{
            borderColor: "rgb(220, 220, 220)",
            cursor: "default",
            overflow: "hidden",
        }}
    >
        <div style={{margin: -token.marginLG}}>
            <Flex
                justify={"space-between"}
                style={{
                    borderBottom: "1px solid rgb(220, 220, 220)",
                    padding: token.paddingSM
                }}
            >
                <Typography.Title level={5} style={{margin: 0}}>
                    <Flex gap={"small"} align={"center"}>
                        <EditOutlined/>
                        Notes
                    </Flex>
                </Typography.Title>
                <Flex align={"center"}>
                    <Button
                        type="text"
                        icon={<MinusOutlined/>}
                        onClick={() => setNotesMinimized && setNotesMinimized(true)}
                    />
                    <Button
                        type="text"
                        icon={<FullscreenOutlined/>}
                        onClick={() => setNotesExpand && setNotesExpand(true)}
                    />
                </Flex>
            </Flex>
            <Notes height={height} autoSize={autoSize}/>
        </div>
    </Card>
}


export const AiSidebarContainer = ({
                                       minimizeAi = false,
                                       minimizeNotes = false,
                                       hideAi = false,
                                       hideNotes = false,
                                       marginTop = 0,
                                       children
                                   }) => {
    const [aiMinimized, setAiMinimized] = useState(minimizeAi);
    const [notesMinimized, setNotesMinimized] = useState(minimizeNotes);
    const [aiExpand, setAiExpand] = useState(false);
    const [notesExpand, setNotesExpand] = useState(false);
    const screens = useBreakpoint();
    const {token} = useToken();

    const smallScreen = !screens.lg;
    const showSideBar = !smallScreen && (
        (!aiMinimized && !hideAi) || (!notesMinimized && !hideNotes));
    const showAiFloat = ((smallScreen && !aiExpand) || aiMinimized) && !hideAi;
    const showNotesFloat = ((smallScreen && !notesExpand) || notesMinimized) && !hideNotes;

    return (
        <AiChatContextProvider>
            <Row gutter={[token.margin, token.margin]}>
                <Col span={showSideBar ? 16 : 24}>
                    {children}
                </Col>
                {showSideBar
                    && <Col span={8}>
                        <div style={{height: marginTop}}/>
                        <Affix offsetTop={16}>
                            <Flex vertical gap={"middle"}>
                                {!aiMinimized && !hideAi && !smallScreen
                                    &&
                                    <AiChatCard
                                        height={notesMinimized ? "calc(100vh - 450px)" : "calc(50vh - 100px)"}
                                        setAiMinimized={setAiMinimized}
                                        setAiExpand={setAiExpand}
                                    />
                                }
                                {!notesMinimized && !hideNotes && !smallScreen
                                    &&
                                    <NotesCard
                                        autoSize={{minRows: 5, maxRows: 8}}
                                        setNotesMinimized={setNotesMinimized}
                                        setNotesExpand={setNotesExpand}
                                    />
                                }
                            </Flex>
                        </Affix>
                    </Col>
                }
            </Row>
            <MobileDrawer
                title={null}
                open={aiExpand}
                onClose={() => setAiExpand(false)}
                closable={true}
                width={640}
                style={{background: token.colorBgGreyDark}}
            >
                <div style={{
                    marginLeft: -token.marginLG,
                    marginRight: -token.marginLG,
                }}>
                    <AiChat height={"calc(100vh - 80px) "}/>
                </div>
            </MobileDrawer>
            <MobileDrawer
                title={null}
                open={notesExpand}
                onClose={() => setNotesExpand(false)}
                closable={true}
                width={640}
                style={{background: token.colorBgGreyDark}}
            >
                <div style={{
                    marginLeft: -token.marginLG,
                    marginRight: -token.marginLG,
                }}>
                    <Notes height={"calc(100vh - 120px)"}/>
                </div>
            </MobileDrawer>
            {showAiFloat
                && <HoliFloatButton onClick={() => {
                    smallScreen ? setAiExpand(true) : setAiMinimized(false)
                }}/>
            }
            {showNotesFloat
                && <FloatButton
                    icon={<EditOutlined/>}
                    onClick={() => {
                        smallScreen ? setNotesExpand(true) : setNotesMinimized(false)
                    }}
                    style={{
                        bottom: 16,
                        insetInlineEnd: showAiFloat ? 138 : 12
                    }}
                />
            }
        </AiChatContextProvider>
    );
}


export default AiSidebarContainer;
