import React from "react";
import {
    Navigate,
    Route,
    Routes,
} from "react-router-dom";
import {PageNotFound} from "@app/layout/retail/RetailLayout";
import Subscription from "@components/retail/profile/Subscription";
import TestSettings from "@components/retail/profile/TestSettings";


const Profile = () => {
    return <Routes>
        <Route path="/" element={<Navigate to="subscription"/>}/>
        <Route path="/subscription" element={<Subscription/>}/>
        <Route path="/test-settings" element={<TestSettings/>}/>
        <Route path="*" element={<PageNotFound/>}/>
    </Routes>
}

export default Profile;
