import axiosInstance from "../APIClient";

const describeWithAi = async (asset, structured=false) => {
    let url = '/assets/describe_with_ai'
    let params = {};
    if(structured) {
        params.structured = true;
    }
    return await axiosInstance.post(url, asset, {params: params})
}

const getExistingAiComment = async (asset, structured=false) => {
    let url = '/assets/get-existing-ai-comment';
    let params = {};
    if(structured) {
        params.structured = true;
    }
    return await axiosInstance.post(url,  asset, {params: params})
}

export {
    describeWithAi,
    getExistingAiComment
}
