import axiosInstance from "./APIClient";
import axios from "axios";

let cancel;

const CLIENT_URL = "/client"

const getClients = (searchText, skip, limit, clientsHandler) => {
    let url = `${CLIENT_URL}/list/combined?search_string=${searchText}`

    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            console.debug(data)
            clientsHandler(data);
        })
        .catch(({response}) => {
            if (response) {
                console.error(response);
            }
        })
};

const createClient = (clientData, callback) => {
    axiosInstance.post(CLIENT_URL, clientData)
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
};

const submitExtendedClient = (clientData, callback) => {
    axiosInstance.post(`${CLIENT_URL}/extended`, clientData)
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
};

const updateExtendedClient = (clientId, clientData, callback) => {
    axiosInstance.put(`${CLIENT_URL}/${clientId}/extended`, clientData, {
        cancelToken: new axios.CancelToken(function executor(c) {
            cancel = c;
        })
    })
        .then(({data}) => {
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const updateAiSettings = (clientId, aiSettings, callback) => {
    axiosInstance.put(`/ai-conversation/${clientId}`, aiSettings, {
        cancelToken: new axios.CancelToken(function executor(c) {
            cancel = c;
        })
    })
        .then(({data}) => {
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const updateClientInfo = (clientId, clientData, callback) => {
    axiosInstance.put(`${CLIENT_URL}/${clientId}`, clientData, {
        cancelToken: new axios.CancelToken(function executor(c) {
            cancel = c;
        })
    })
        .then(({data}) => {
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const createFromOrion = (orionId, callback) => {
    axiosInstance.post(`${CLIENT_URL}/orion/${orionId}`)
        .then((res) => {
            callback(res.data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
};

const saveQuestionnaire = (clientId, questionnaire, callback) => {
    axiosInstance.put(`${CLIENT_URL}/${clientId}/questionnaire`, questionnaire)
        .then((res) => {
            callback(res.data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const setRelatedInfo = (clientId, key, value, callback) => {
    axiosInstance.put(`${CLIENT_URL}/${clientId}/related_info`, {'key': key, value: value})
        .then((res) => {
            callback(res.data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const saveAssumptions = (clientId, assumptions, callback) => {
    axiosInstance.post(`${CLIENT_URL}/${clientId}/assumptions`, assumptions)
        .then((res) => {
            callback(res.data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const deleteClient = (clientId, callback) => {
    axiosInstance.delete(`${CLIENT_URL}/${clientId}`,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            console.debug(data)
            callback("The client was removed successfully");
        })
        .catch(({response}) => {
            callback(response, true);
        })
};

const editClient = (clientId, clientData, callback) => {
    delete clientData.owner
    axiosInstance.put(`${CLIENT_URL}/${clientId}`, clientData,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            console.debug(data);
            callback(`${data.full_name} was changed successfully`);
        })
        .catch(({response}) => {
            callback(response, true);
        })
};

const getClient = (clientId, callback) => {
    axiosInstance.get(`${CLIENT_URL}/${clientId}`,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            console.debug(data);
            callback(data, false);
        })
        .catch(({response}) => {
            callback(response, true);
        })
};

const loadFullClient = (clientId, clientHandler) => {
    let url = `${CLIENT_URL}/full/${clientId}`
    axiosInstance.get(url, {
        cancelToken: new axios.CancelToken(function executor(c) {
            cancel = c;
        })
    })
        .then(({data}) => {
            console.debug(data)
            clientHandler(data, false)
        })
        .catch(({response}) => {
            console.error(response);
            if ([403, 404].includes(response?.status)) {
                clientHandler(response.data, true)
            } else {
                clientHandler({"message": "Unknown Error"}, true)
            }

        }).finally(() => {

    })
}

const getAiThoughts = async (clientId, type) => {
    let url = `${CLIENT_URL}/ai/${type}/${clientId}`
    return await axiosInstance.get(url)
}

const getAiJson = async (id, type) => {
    let url = `/ai-conversation/structured_comments/{_id}`
    return await axiosInstance.get(url)
}

const searchOrionAccounts = (searchString, callback, abortSignal) => {
    let url = `${CLIENT_URL}/search/orion-accounts`
    if (searchString) {
        url += '?search_string=' + searchString
    }
    axiosInstance.get(url, {signal: abortSignal})
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            console.error(response);
            callback(response, true);
        })
};

const getRetailClientId = (callback) => {
    let url = `${CLIENT_URL}/retail/proposal_id`

    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            console.debug(data)
            callback(data);
        })
        .catch(({response}) => {
            console.error(response);
            callback(response, true);
        })
};


const getPlaidLinkToken = (clientId, callback) => {
    let url = `${CLIENT_URL}/${clientId}/plaid/api/create-link-token`

    axiosInstance.get(url,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            console.debug(data);
            callback(data);
        })
        .catch(({response}) => {
            console.error(response);
            callback(response, true);
        })
};


const exchangePlaidPublicToken = (clientId, publicToken, callback) => {
    let url = `${CLIENT_URL}/${clientId}/plaid/api/exchange-public-token`
    console.log("exchangePlaidPublicToken publicToken", publicToken)
    axiosInstance.post(url, publicToken,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            console.debug(data);
            callback(data);
        })
        .catch(({response}) => {
            console.error(response);
            callback(response, true);
        })
};


export {
    getClients,
    getClient,
    getRetailClientId,
    loadFullClient,
    submitExtendedClient,
    updateExtendedClient,
    createClient,
    updateClientInfo,
    editClient,
    deleteClient,
    createFromOrion,
    saveQuestionnaire,
    setRelatedInfo,
    saveAssumptions,
    getAiThoughts,
    getAiJson,
    searchOrionAccounts,
    updateAiSettings,
    getPlaidLinkToken,
    exchangePlaidPublicToken,
    cancel
}
