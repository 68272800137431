import React from "react";
import {
    Checkbox,
    Flex,
    Input,
    theme,
    Tooltip,
} from "antd";
import {
    useDebounce,
    useUpdateEffect,
} from "ahooks";
import {setRelatedInfo} from "@API/clients";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";


const {useToken} = theme;


export const Notes = ({height = "auto", autoSize = false}) => {
    const {
        id,
        notes,
        setNotes,
        notesInReport,
        setNotesInReport
    } = useHolisticoProposalContext();
    const debouncedContent = useDebounce(notes, {wait: 1000});
    const {token} = useToken();

    useUpdateEffect(() => {
        if (id) {
            setRelatedInfo(id, "notes", debouncedContent, (result, error) => {})
        }
    }, [debouncedContent]);

    return <Flex vertical>
        <Input.TextArea
            height={height}
            autoSize={autoSize}
            value={notes}
            onChange={(e) => {
                setNotes(e.target.value);
            }}
            placeholder={"Notes about portfolios, client's employment, major expenses, etc."}
            variant={"borderless"}
            style={{
                background: "white",
                height: height,
                padding: token.padding,
            }}
        />
        <div style={{
            background: token.colorBgGreyDark,
            borderTop: "1px solid rgb(220, 220, 220)",
            padding: token.paddingSM
        }}>
            <Tooltip
                title='Include notes in the PDF report as "Client description" section'>
                <Checkbox
                    checked={notesInReport}
                    onChange={(e) => setNotesInReport(e.target.checked)}
                >
                    Include in report
                </Checkbox>
            </Tooltip>
        </div>
    </Flex>
}


export default Notes;
