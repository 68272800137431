import React, {createContext, useContext, useEffect, useState} from "react";
import {useDynamicList} from "ahooks";
import {message} from "antd";
import {sendChatMessage} from "@API/ai";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";


const RETAIL_FIRST_MESSAGE = (
    "Hi, I’m Holi, your personal AI financial assistant. " +
    "Feel free to ask me anything about your portfolio, assets, " +
    "risk profile, etc."
);
const RIA_FIRST_MESSAGE = (
    "Hi, I’m Holi, your personal AI financial assistant. " +
    "Feel free to ask me anything but keep in mind I’m not " +
    "a financial advisor and all investment " +
    "decisions are your own."
);
const NOT_READY_MESSAGE = (
    "Hi, I'm Holi, your personal AI financial assistant. " +
    "Add client portfolio information so we have something to talk about"
);
const NOT_READY_REPLY_MESSAGE_1 = (
    "Client's portfolio information not ready. " +
    "Nothing to talk about yet..."
)
const NOT_READY_REPLY_MESSAGE_2 = (
    "Try uploading the portfolio. " +
    "Or, if you've already done it, wait a little " +
    "and ask me again..."
)
const RIA_PREDEFINED_MESSAGES = [
    {
        key: "prosandcons",
        label: "Pros & cons",
        message: "Write 5 pros and 5 cons for the portfolio",
    },
    {
        key: "suggestions",
        label: "Suggestions",
        message: "What changes can enhance the portfolio?",
    },
    {
        key: "points",
        label: "Talking points",
        message: "Give me 5 points to discuss with the client",
    },
    {
        key: "impression",
        label: "Overall impression",
        message: "What's your overall client impression?",
    },
    {
        key: "vs_macro",
        label: "In market context",
        message: "What you can say about the client's portfolio " +
            "in the context of the current economic situation?"
    },
]
const RETAIL_PREDEFINED_MESSAGES = [
    {
        key: "suggestions",
        label: "Suggestions",
        message: "What changes can enhance my portfolio?",
    },
    {
        key: "buy_list",
        label: "Buy-list",
        message: "I have $10000. " +
            "What can I buy to improve my portfolio?",
    },
    {
        key: "risk_reduction",
        label: "Risk reduction",
        message: "How can I reduce the risk of my portfolio?",
    },
    {
        key: "impression",
        label: "Overall impression",
        message: "What's your overall impression of my portfolio?",
    },
    {
        key: "vs_macro",
        label: "In market context",
        message: "What you can say about my portfolio in the context " +
            "of the current economic situation?"
    },
]


const getDefaultMessageList = (messageList, proposal, retail=false) => {
    if (!proposal?.p_bucket_cur?.calculated_values?.risk) {
        return [{
            index: -1,
            role: "assistant",
            status: "info",
            content: NOT_READY_MESSAGE,
        }]
    }
    const first = {
        index: -1,
        role: "assistant",
        status: "info",
        content: retail ? RETAIL_FIRST_MESSAGE : RIA_FIRST_MESSAGE,
    };
    return [first, ...(messageList ?? [])];
}


const AiChatContext = createContext(null);


const AiChatContextProvider = ({retail = false, children}) => {
    const [waitingForResponse, setWaitingForResponse] = useState(false);
    const [newMsgTrigger, setNewMsgTrigger] = useState(0);
    const {conversation : chatHistory, proposal} = useHolisticoProposalContext();

    const {
        list: messageList,
        pop,
        push,
        resetList
    } = useDynamicList(getDefaultMessageList(chatHistory, proposal, retail));

    const getPredefinedMessageByKey = (key) => {
        return (retail ? RETAIL_PREDEFINED_MESSAGES : RIA_PREDEFINED_MESSAGES)
            .filter(obj => {return obj.key === key})[0]?.message;
    }

    const sendMessage = (msg) => {
        if (!msg || msg === "" || /^\s*$/.test(msg)) {
            // empty message
            return;
        }

        push({"content": msg, "role": "user"});
        setNewMsgTrigger((cur) => cur + 1);
        if (!proposal?.p_bucket_cur?.calculated_values?.risk) {
            // portfolio not ready
            setTimeout(() => {
                push({role: 'assistant', content: NOT_READY_REPLY_MESSAGE_1});
                setNewMsgTrigger((cur) => cur + 1);
            }, 500)
            setTimeout(() => {
                push({role: 'assistant', content: NOT_READY_REPLY_MESSAGE_2})
                setNewMsgTrigger((cur) => cur + 1)
            }, 1500)
            return;
        }
        push({role: 'assistant-thinking', content: "Holi is thinking..."})
        setNewMsgTrigger((cur) => cur + 1);

        try {
            setWaitingForResponse(true);
            sendChatMessage(proposal?._id, msg, "user", (data, err) => {
                setWaitingForResponse(false);
                pop();
                if (err) {
                    console.error(err)
                    message.error("ai communication error")
                } else {
                    console.debug(data);
                    push(data);
                    setNewMsgTrigger((cur) => cur + 1);
                }
            })
        } catch (e) {
            console.error(e);
            message.error("ai communication error");
            setWaitingForResponse(false);
            pop();
        }
    }

    useEffect(() => {
        resetList(getDefaultMessageList(chatHistory, proposal, retail));
        setNewMsgTrigger((cur) => cur + 1);
    }, [proposal, chatHistory])


    return <AiChatContext.Provider
        value={{
            messageList,
            sendMessage,
            waitingForResponse,
            newMsgTrigger,
            setNewMsgTrigger,
            predefinedMessages: retail ? RETAIL_PREDEFINED_MESSAGES : RIA_PREDEFINED_MESSAGES,
            getPredefinedMessageByKey,
            retail
        }}
    >
        {children}
    </AiChatContext.Provider>
}


const useAiChatContext = () => {
    const context = useContext(AiChatContext);
    if (!context) {
        throw new Error(
            "useAiChatContext must be used within AiChatContextContextProvider"
        )
    }
    return context;
}


export {AiChatContextProvider, useAiChatContext}
