import React, {useState} from "react";
import {
    Badge,
    Button,
    Card,
    Flex, Grid,
    List,
    theme,
    Typography
} from "antd";
import {useRequest, useUpdateEffect} from "ahooks";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {getMacroStats} from "@API/ahooks/dashboard";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import {useAuth} from "@hooks/AuthHook";


const {useBreakpoint} = Grid;
const {useToken} = theme;


const COLUMNS = [
    {
        column_name: "real_gdp_growth_1q_annualized",
        title: "GDP Growth",
        subtitle: "Real, last quarter, annualized, seasonally-adjusted",
        highIsGood: true,
        formatter: (x) => ValueFormatter.oneDigitPercent(x / 100)
    },
    {
        column_name: "inflation_rate",
        title: "Inflation Rate",
        subtitle: "Last 12 months, seasonally-adjusted",
        highIsGood: false,
        formatter: (x) => ValueFormatter.oneDigitPercent(x / 100)
    },
    {
        column_name: "unemployment_rate",
        title: "Unemployment Rate",
        subtitle: "Seasonally-adjusted",
        highIsGood: false,
        formatter: (x) => ValueFormatter.oneDigitPercent(x / 100)
    },
    {
        column_name: "personal_saving_rate",
        title: "Personal Saving Rate",
        subtitle: "Annualized, seasonally-adjusted",
        highIsGood: true,
        formatter: (x) => ValueFormatter.oneDigitPercent(x / 100)
    },
    {
        column_name: "vix",
        title: "Volatility Index",
        subtitle: "CBOE Volatility Index",
        highIsGood: false,
        formatter: (x) => ValueFormatter.round(x, 1)
    },
    {
        column_name: "federal_funds_rate",
        title: "Federal Funds Rate",
        subtitle: "Effective federal funds rate",
        highIsGood: false,
        formatter: (x) => ValueFormatter.oneDigitPercent(x / 100)
    },
    {
        column_name: "housing_starts",
        title: "Housing starts",
        subtitle: "New privately-owned housing units started, annual, seasonally adjusted",
        highIsGood: true,
        formatter: (x) => ValueFormatter.int_number(x) + "k"
    },
    {
        column_name: "real_retail_sales_growth_1m_annualized",
        title: "Retail Sales Growth",
        subtitle: "Real, last month, annualized, seasonally-adjusted",
        highIsGood: true,
        formatter: (x) => ValueFormatter.oneDigitPercent(x / 100)
    },

    {
        column_name: "real_personal_consumption_expenditures_growth_1m_annualized",
        title: "Personal Consumption Growth",
        subtitle: "Real, last month, annualized, seasonally-adjusted",
        highIsGood: true,
        formatter: (x) => ValueFormatter.oneDigitPercent(x / 100)
    },
    {
        column_name: "real_disposable_personal_income_growth_1m_annualized",
        title: "Personal Income Growth",
        subtitle: "Real, last month, annualized, seasonally-adjusted",
        highIsGood: true,
        formatter: (x) => ValueFormatter.oneDigitPercent(x / 100)
    },
]


const MacroeconomicStatisticItem = ({item, style}) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    let status = 'default';
    if (item.quantile >= 0.6) {
        status = item.highIsGood ? "success" : "error";
    }
    if (item.quantile <= 0.4) {
        status = item.highIsGood ? "error" : "success";
    }

    return <Flex
        vertical
        gap={"small"}
        justify={"space-between"}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{...style}}
    >
        <Flex vertical>
            <Typography.Title level={5} style={{
                margin: 0,
                lineHeight: 1.2,
                fontSize: token.fontSize
            }}>
                {item.title}
            </Typography.Title>
            <Typography.Paragraph
                type={"secondary"} ellipsis={{rows: 1}}
                style={{
                    fontSize: token.fontSizeSM,
                    lineHeight: 1.2,
                    margin: 0
                }}
            >
                {item.subtitle}
            </Typography.Paragraph>
        </Flex>
        <Flex vertical style={{
            background: hover ? token.colorBgHoverBlue : token.colorBgGrey,
            borderRadius: token.borderRadiusLG,
            padding: token.paddingSM,
        }}>
            <Flex justify={"space-between"}>
                <Typography.Text style={{
                    fontSize: token.fontSizeHeading3,
                    fontWeight: token.fontWeightStrong,
                    lineHeight: 1,
                }}>
                    {is_null(item.value) ? "-" : item.formatter(item.value)}
                </Typography.Text>
                {item.ai_comment?.comment
                    && <Flex
                        justify={"center"}
                        align={"center"}
                        onClick={() => setOpenDrawer(true)}
                        style={{
                            background: "white",
                            borderRadius: 36,
                            width: 30,
                            height: 30
                        }}
                    >
                        <Button type={"link"} style={{
                            padding: 0,
                            fontSize: token.fontSizeSM,
                            fontWeight: 800
                        }}>
                            i
                        </Button>
                    </Flex>
                }
            </Flex>
            <Flex gap={"small"} align={"baseline"}>
                <Badge dot status={status}/>
                <Typography.Text style={{
                    fontSize: token.fontSizeSM,
                    lineHeight: 1.2,
                    marginTop: token.paddingXS
                }}>
                    {item.quantile >= 0.5
                        && "Higher than "
                        + ValueFormatter.softPercent(item.quantile)
                        + " of historical values"
                    }
                    {item.quantile < 0.5
                        && "Lower than "
                        + ValueFormatter.softPercent(1 - item.quantile)
                        + " of historical values"
                    }
                    {is_null(item.quantile) && "-"}
                </Typography.Text>
            </Flex>
        </Flex>
        <MobileDrawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            width={600}
        >
            <Card>
                <Typography.Title level={4} style={{margin: 0}}>
                    {item.title}
                </Typography.Title>
                <Typography.Title level={5}>
                    Holi's comment
                </Typography.Title>
                {item.ai_comment?.comment
                    ? item.ai_comment?.comment.split("\n").map((par, index) => {
                        return <Typography.Paragraph key={index}>
                            {par}
                        </Typography.Paragraph>
                    })
                    : <Typography.Paragraph>
                        Holi's comment not found
                    </Typography.Paragraph>
                }
            </Card>
        </MobileDrawer>
    </Flex>
}


const MacroStatsCard = ({title}) => {
    const [items, setItems] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const screens = useBreakpoint();
    const {token} = useToken();
    const {sendGAEvent} = useAuth();

    const columnNames = COLUMNS.map(x => x.column_name);
    let nCards;
    if (screens.xl) {
        nCards = 4;
    } else if (screens.lg) {
        nCards = 3;
    } else if (screens.md) {
        nCards = 3;
    } else if (screens.sm) {
        nCards = 4;
    } else {
        nCards = 3;
    }

    const {
        data: response,
        loading,
        error
    } = useRequest(() => getMacroStats(columnNames));

    useUpdateEffect(() => {
        if (error) {
            console.error(error.message)
        } else {
            console.error('Error while loading macroeconomic statistics')
        }
    }, [error])

    useUpdateEffect(() => {
        console.debug("Macro statis data loaded", response.data);
        const _items = [];
        COLUMNS.map(x => {
            _items.push({...x, ...response.data[x.column_name]})
        })
        setItems(_items);
    }, [response])

    return <Card hoverable style={{cursor: "default"}}>
        <List
            loading={loading}
            dataSource={expanded ? items : items.filter((_, index) => index < nCards)}
            bordered={false}
            renderItem={(item, index) => {
                return <List.Item key={index} style={{height: "100%", margin: 0}}>
                    <MacroeconomicStatisticItem
                        key={index}
                        item={item}
                        style={{height: "100%"}}
                    />
                </List.Item>
            }}
            grid={{
                gutter: [token.padding, token.padding],
                xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4
            }}
        />
        <Button
            type="link"
            size={"small"}
            icon={expanded ? <UpOutlined/> : <DownOutlined/>}
            onClick={() => {
                setExpanded(prev => !prev);
                sendGAEvent({
                    category: 'Macro Stats',
                    action: 'Click',
                    label: 'Show all/less'
                });
            }}
            style={{padding: 0, marginTop: token.padding}}
        >
            {expanded ? "Show less" : "Show all"}
        </Button>
    </Card>
}

export default MacroStatsCard;
