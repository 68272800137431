import {Row, Col, Flex, theme, Typography, message, Spin, Button} from "antd";
import React, {useEffect, useRef, useState} from "react";
import ModelSelectInput from './ModelSelectInput';
import CustomSelectInput
    from "@holistico/proposal/new/preview/CustomSelectInput";
import SectorBar from "@holistico/chart/SectorBar";
import StatsList from "@holistico/proposal/new/preview/StatsList";
import ValueFormatter from "@global/ValueFormatter";
import MaturityDateBar from "@holistico/chart/MaturityDateBar";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {
    assetAllocationDescription
} from "@holistico/proposal/view/fundamentals/AssetAllocation";
import {
    ratingsDescription, sectorsDescription, sizeDescription, styleDescription
} from "@holistico/proposal/view/fundamentals/EquitiesPlain";
import {
    creditRatingDescription, fiSectorDescription, maturityDateDescription
} from "@holistico/proposal/view/fundamentals/FixedIncomePlain";
import AssetClassPie from "@holistico/chart/AssetClassPie";
import {
    FundamentalsItemJoint
} from "@holistico/proposal/view/fundamentals/FundamentalsItem";
import {useRequest, useSize} from "ahooks";
import ScoreMarkersRadar from "@holistico/chart/ScoreMarkersRadar";
import SizePie from "@holistico/chart/SizePie";
import SizeStyleHeat from "@holistico/chart/SizeStyleHeat";
import CreditRatingChart from "@holistico/chart/CreditRatingChart";
import FISectorPie from "@holistico/chart/FISectorPie";
import {describeWithAi} from "@API/ahooks/asset";


const {useToken} = theme;


const ComparativePortfolioPreview = () => {
    const {
        proposal: client,
        aiAnalyticalComments,
        loadingAiAnalyticalComments,
        benchmark,
        setBenchmark
    } = useHolisticoProposalContext();
    const [benchmarkCalculatedValues, setBenchmarkCalculatedValues] = useState({})
    const [aiAnalyticalCommentsBenchmark, setAiAnalyticalCommentsBenchmark] = useState({})
    const ref = useRef(null);
    const size = useSize(ref);
    const {token} = useToken();

    const show = "both";
    const vertical = size?.width < 800;

    const benchmarkSelected = (portfolio) => {
        setBenchmarkCalculatedValues(portfolio.calculated_values);
        setBenchmark(portfolio);
        console.log('benchmarkSelected', portfolio)
    }

    const {
        runAsync: getBenchmarkAiComments,
        loading: loadingAiAnalyticalCommentsBenchmark,
    } = useRequest(describeWithAi, {manual: true});

    useEffect(() => {
        if (benchmark) {
            const asset = {
                security_type: 'Portfolio',
                definition_id: benchmark._id,
                ticker: benchmark.ticker
            }

            getBenchmarkAiComments(asset, true).then((result) => {
                console.log(result, result.data)
                if (result) {
                    setAiAnalyticalCommentsBenchmark(result.data);
                }
            }).catch((error) => {
            }).finally()
        }
    }, [benchmark])

    return <div ref={ref}>
        <Row gutter={[token.marginLG, token.marginLG]} style={{marginBottom: token.marginXXL}}>
            <Col span={vertical? 24 : 12}>
                <Flex vertical gap={"small"}>
                     <Typography.Text type={"secondary"} style={{fontSize: token.fontSizeSM, lineHeight: 1.2}}>
                        Client's portfolio
                    </Typography.Text>
                    <CustomSelectInput items={[{
                        label: <Flex gap={"large"} justify={"space-between"} align={"baseline"}>
                            <Typography.Text ellipsis style={{fontWeight: token.fontWeightStrong}}>
                                Current portfolio
                                ({ValueFormatter.currency(client?.p_bucket_cur?.calculated_values?.market_value)})
                            </Typography.Text>
                        </Flex>,
                        value: 'Current Portfolio'
                    }]}/>
                    <StatsList calculated_values={client?.p_bucket_cur?.calculated_values}/>
                </Flex>
            </Col>
            <Col span={vertical? 24 : 12}>
                <Flex vertical gap={"small"}>
                    <Typography.Text type={"secondary"} style={{fontSize: token.fontSizeSM, lineHeight: 1.2}}>
                        Benchmark
                    </Typography.Text>
                    <ModelSelectInput
                        onChange={benchmarkSelected}
                        risk_score={client?.p_bucket_cur?.calculated_values?.risk?.risk_score}
                    />
                    <StatsList calculated_values={benchmarkCalculatedValues}/>
                </Flex>

            </Col>
        </Row>

        <FundamentalsItemJoint
            title="Asset allocation"
            description={assetAllocationDescription}
            proposalLabel="Benchmark"
            chartCurrent={
                <AssetClassPie
                    allocation={client?.p_bucket_cur?.calculated_values?.asset_class}
                    height={250}
                />
            }
            chartProposed={
                <AssetClassPie
                    allocation={benchmarkCalculatedValues?.asset_class}
                    height={250}
                />
            }
            commentCurrent={aiAnalyticalComments?.asset_class ?? "No asset class information"}
            commentProposed={aiAnalyticalCommentsBenchmark?.asset_class ?? "No asset class information"}
            aiCommentLoading={loadingAiAnalyticalComments || loadingAiAnalyticalCommentsBenchmark}
            vertical={vertical}
            commonChart={false}
            show={show}
            style={{marginTop: token.margin}}
        />
        <FundamentalsItemJoint
            title="Equity ratings"
            description={ratingsDescription}
            proposalLabel="Benchmark"
            chartCurrent={
                <ScoreMarkersRadar
                    score_markers={client?.p_bucket_cur?.calculated_values?.score_markers}
                    height={280}
                />
            }
            chartProposed={
                <ScoreMarkersRadar
                    score_markers={benchmarkCalculatedValues?.score_markers}
                    height={280}
                />
            }
            commentCurrent={aiAnalyticalComments?.score_markers ?? "No ratings information"}
            commentProposed={aiAnalyticalCommentsBenchmark?.score_markers ?? "No ratings information"}
            aiCommentLoading={loadingAiAnalyticalComments || loadingAiAnalyticalCommentsBenchmark}
            vertical={vertical}
            commonChart={false}
            show={show}
        />
        <FundamentalsItemJoint
            title="Equity sector"
            description={sectorsDescription}
            proposalLabel="Benchmark"
            chartCurrent={
                <SectorBar
                    allocation={
                        ["both", "current"].includes(show)
                            ? client?.p_bucket_cur?.calculated_values?.sector
                            : {}
                    }
                    proposalAllocation={
                        ["both", "proposed"].includes(show)
                            ? benchmarkCalculatedValues?.sector
                            : {}
                    }
                    proposalLabel={"Benchmark"}
                    showLegend={show === "both"}
                    color={show === "proposed" ? "#E0AD60" : ["#3196B2", "#E0AD60"]}
                />
            }
            commentCurrent={aiAnalyticalComments?.sector ?? "No sector information"}
            commentProposed={aiAnalyticalCommentsBenchmark?.sector ?? "No sector information"}
            aiCommentLoading={loadingAiAnalyticalComments || loadingAiAnalyticalCommentsBenchmark}
            vertical={vertical}
            commonChart={true}
            show={show}
        />
        <FundamentalsItemJoint
            title="Equity size"
            description={sizeDescription}
            proposalLabel="Benchmark"
            chartCurrent={
                <SizePie
                    allocation={client?.p_bucket_cur?.calculated_values?.size}
                    height={280}
                />
            }
            chartProposed={
                <SizePie
                    allocation={benchmarkCalculatedValues?.size}
                    height={280}
                />
            }
            commentCurrent={aiAnalyticalComments?.size ?? "No size information"}
            commentProposed={aiAnalyticalCommentsBenchmark?.size ?? "No size information"}
            aiCommentLoading={loadingAiAnalyticalComments || loadingAiAnalyticalCommentsBenchmark}
            vertical={vertical}
            commonChart={false}
            show={show}
        />
        <FundamentalsItemJoint
            title="Equity style"
            description={styleDescription}
            proposalLabel="Benchmark"
            chartCurrent={
                <SizeStyleHeat
                    allocation={client?.p_bucket_cur?.calculated_values?.size_style}
                    height={280}
                />
            }
            chartProposed={
                <SizeStyleHeat
                    allocation={benchmarkCalculatedValues?.size_style}
                    height={280}
                />
            }
            commentCurrent={aiAnalyticalComments?.style ?? "No style information"}
            commentProposed={aiAnalyticalCommentsBenchmark?.style ?? "No style information"}
            aiCommentLoading={loadingAiAnalyticalComments || loadingAiAnalyticalCommentsBenchmark}
            vertical={vertical}
            commonChart={false}
            show={show}
        />
        <FundamentalsItemJoint
            title="Credit rating"
            description={creditRatingDescription}
            proposalLabel="Benchmark"
            chartCurrent={
                <div style={{marginTop: token.marginSM}}>
                    <CreditRatingChart allocation={client?.p_bucket_cur?.calculated_values?.fi_credit_rating}/>
                </div>
            }
            chartProposed={
                <div style={{marginTop: token.marginSM}}>
                    <CreditRatingChart allocation={benchmarkCalculatedValues?.fi_credit_rating}/>
                </div>

            }
            commentCurrent={aiAnalyticalComments?.fi_credit_rating ?? "No credit rating information"}
            commentProposed={aiAnalyticalCommentsBenchmark?.fi_credit_rating ?? "No credit rating information"}
            aiCommentLoading={loadingAiAnalyticalComments || loadingAiAnalyticalCommentsBenchmark}
            vertical={vertical}
            commonChart={false}
            show={show}
        />
        <FundamentalsItemJoint
            title="Fixed-income sector"
            description={fiSectorDescription}
            proposalLabel="Benchmark"
            chartCurrent={
                <FISectorPie
                    allocation={client?.p_bucket_cur?.calculated_values?.fi_super_sector}
                    height={280}
                />
            }
            chartProposed={
                 <FISectorPie
                     allocation={benchmarkCalculatedValues?.fi_super_sector}
                     height={280}
                 />
            }
            commentCurrent={aiAnalyticalComments?.fi_super_sector ?? "No sector information"}
            commentProposed={aiAnalyticalCommentsBenchmark?.fi_super_sector ?? "No sector information"}
            aiCommentLoading={loadingAiAnalyticalComments || loadingAiAnalyticalCommentsBenchmark}
            vertical={vertical}
            commonChart={false}
            show={show}
        />
        <FundamentalsItemJoint
            title="Maturity date"
            description={maturityDateDescription}
            proposalLabel="Benchmark"
            chartCurrent={
               <MaturityDateBar
                    allocation={
                        ["both", "current"].includes(show)
                            ? client?.p_bucket_cur?.calculated_values?.fi_effective_maturity
                            : {}
                    }
                    proposalAllocation={
                        ["both", "proposed"].includes(show)
                            ? benchmarkCalculatedValues?.fi_effective_maturity
                            : {}
                    }
                    proposalLabel={"Benchmark"}
                    showLegend={show === "both"}
                    color={(show === "proposed" || Object.keys(client?.p_bucket_cur?.calculated_values?.fi_effective_maturity ?? {}).length === 0) ? "#E0AD60" : ["#3196B2", "#E0AD60"]}
                />
            }
            commentCurrent={aiAnalyticalComments?.fi_effective_maturity ?? "No maturity date information"}
            commentProposed={aiAnalyticalCommentsBenchmark?.fi_effective_maturity ?? "No maturity date information"}
            aiCommentLoading={loadingAiAnalyticalComments || loadingAiAnalyticalCommentsBenchmark}
            vertical={vertical}
            commonChart={true}
            show={show}
        />
    </div>
}


export default ComparativePortfolioPreview
