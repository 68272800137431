import {Button, theme} from "antd";
import {useRequest} from 'ahooks';
import {useAuth} from "@hooks/AuthHook";
import React from "react";
import {start_pay_session} from "@API/ahooks/subscriptions";


const {useToken} = theme;


const SubscriptionButton = ({
                                priceId,
                                buttonText = "Subscribe",
                                type = "primary",
                                size = "large",
                                style = {}
                            }
) => {
    const {token} = useToken();
    const {user} = useAuth();
    const current_price_id = user.current_subscription?.plan?.id
    const current = current_price_id == priceId

    const {loading, run} = useRequest(start_pay_session, {
        manual: true,
        onSuccess: (result) => {
            console.log('Created the session:', result);
            window.location.href = result.data.url;
        },
        onError: (error) => {
            console.error('Error creating pyament session:', error);
        },
    });

    const handleClick = () => {
        console.log(priceId)
        run(priceId);
    };

    if (current) {
        return <Button
            onClick={handleClick}
            type={"primary"} disabled size={size} style={{
            width: "100%",
            marginTop: token.marginXL
        }}>
            Current plan
        </Button>
    } else {
        return <Button
            type={type}
            loading={loading}
            size={size}
            onClick={handleClick}
            style={style}
        >
            {buttonText}
        </Button>
    }
};

export default SubscriptionButton;