import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {
    Card,
    Form,
    InputNumber,
    Rate,
    Select,
    theme
} from "antd";
import {AimOutlined, CaretRightOutlined} from "@ant-design/icons";
import React from "react";
import {roles} from "@components/holistico/proposal/view/ai/role_models";
import CardHeader from "@components/retail/cards/CardHeader";
import NestedCard from "@components/retail/cards/NestedCard";


const {useToken} = theme;


const prepareKey = (key) => {
    return key.charAt(0).toUpperCase() + key.slice(1);
}


const ConfigurationForm = ({style={}}) => {
    const [form] = Form.useForm();
    const {aiSettings, modifyAiSettings} = useHolisticoProposalContext();
    const {token} = useToken();

    if (!aiSettings) {
        return null;
    }

    return <Card hoverable style={{
        cursor: "default",
        overflow: "hidden",
        background: token.colorBgGrey,
        border: "1px solid rgb(220, 220, 220)",
        minWidth: 325,
        ...style
    }}>
        {aiSettings
            && <Form
                form={form}
                layout={'vertical'}
                initialValues={aiSettings}
                requiredMark={false}
                size={"small"}
                onValuesChange={(changes, allValues) => {
                    if (changes.role_model) {
                        //role_model change is being processed directly by the component
                        return
                    }
                    modifyAiSettings(allValues);
                }}
                onFinish={(values) => {
                    let formValues = {...values};
                    modifyAiSettings(formValues);
                }}
            >
                <NestedCard background={"white"}>
                    <CardHeader title={"Role model"}/>
                <Form.Item name={'role_model'} style={{margin: 0}}>
                    <Select
                        size={"large"}
                        style={{width: '100%'}}
                        onChange={(value) => {
                            let roleModel = roles.find(role => role.label === value)
                            for (let key of Object.keys(roleModel.character_params)) {
                                form.setFieldValue(
                                    ['character_params', key], roleModel.character_params[key]
                                )
                                console.log(`setting ${key} to ${roleModel.character_params[key]}`)
                                form.submit()
                            }

                        }}
                        placeholder={"Select Holi's role model"}
                    >
                        {roles.map(roleModel => {
                            return <Select.Option
                                key={roleModel.label}
                                value={roleModel.label}
                            >
                                {roleModel.name}
                            </Select.Option>
                        })}
                    </Select>
                </Form.Item>
                </NestedCard>
                <Form.Item
                    hidden={true}
                    name={"model"}
                    label={"Ai Model"}
                >
                    <Select options={[{
                        value: 'gtp-4',
                        label: 'GPT',
                    }, {
                        value: 'claude',
                        label: 'Claude AI'
                    }]}/>
                </Form.Item>
                <Form.Item
                    hidden={true}
                    name={"max_tokens"}
                    label={"Answer size"}
                >
                    <InputNumber/>
                </Form.Item>
                {Object.keys(aiSettings?.character_params)?.map(key=> {
                    return <Form.Item
                        key={key}
                        label={prepareKey(key)}
                        name={['character_params', key]}
                    >
                        <Rate
                            count={10}
                            character={<CaretRightOutlined/>}
                            style={{
                                fontSize: 20,
                                // color: '#93C2F0'
                                // color: token.colorStats
                                color: token.colorPrimary
                            }}
                        />
                    </Form.Item>
                })}
            </Form>
        }
    </Card>
}

export default ConfigurationForm;
