import React from "react";
import {App, ConfigProvider, Spin} from "antd";
import {useAuth} from "@hooks/AuthHook";
import RetailLayout from "@app/layout/retail/RetailLayout";


const AppRetail = ({}) => {
    const {user} = useAuth();
    return <Spin spinning={null == user} style={{height: 800}} size={"large"} delay={500}>
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#3F8DFA",
                    colorError: "#f5222d",
                    colorSuccess: "#49aa19",
                    fontSizeHeading1: 48,
                    fontFamily: 'Red Hat Display W, Helvetica, Arial, sans-serif',
                    fontSize: 16,
                    fontWeightStrong: 450,
                    colorBgBase: '#ffffff',
                    headerBg: 'white',
                    // borderRadiusSM: 8,
                    // borderRadius: 16,
                    // borderRadiusLG: 24,
                    // borderRadiusSM: 4,
                    // borderRadius: 8,
                    // borderRadiusLG: 16,
                    fontSizeXS: 12,
                    // colorBgGrey: "rgb(245, 245, 245)",
                    // colorBgGreyDark: "rgb(235, 235, 235)",
                    colorBgGrey: "rgb(248, 248, 248)",
                    colorBgGreyDark: "rgb(240, 240, 240)",
                    colorBgHoverBlue: "#e6f7ff",
                    colorPrimaryGrey: "rgb(100, 100, 100)",
                    colorPrimaryHoverGrey: "rgb(140, 140, 140)",
                    colorStats: "#e0ad60",
                    colorRadioSelectedBg: '#e0ad60',
                },
                components: {
                    Dropdown: {borderRadiusLG: 8},
                    Drawer: {fontSizeLg: 24},
                    Table: {borderRadiusLG: 8},
                }
            }}>
            <App
                className={'holistico retail'}
            >
                {user ? <RetailLayout/> : <div style={{height: 600}}/>}
            </App>
        </ConfigProvider>
    </Spin>
}

export default AppRetail;
