/* eslint-disable react/prop-types */
import React from 'react';
import {
    Button,
    Flex,
    Form,
    InputNumber,
} from "antd";
import {useScreenerContext} from "@hooks/ScreenerContext";
import {updateSearchParams} from "@components/screener/params/utils";
import {useAuth} from "@hooks/AuthHook";


export const NumericFilterTipButton = ({name, from, to, disabled=false, children}) => {
    const {settings, setSearchParams} = useScreenerContext()
    const form = Form.useFormInstance()
    const {sendGAEvent} = useAuth();

    const onClick = (e) => {
        const params = {}
        params[`${name}.from`] = from;
        params[`${name}.to`] = to;
        setSearchParams(prev => updateSearchParams(prev, params, settings, form))
        sendGAEvent({
            category: 'Screener Numeric Filter',
            action: 'Click',
            label: `${name} from ${from} to ${to}`
        })
    }

    return <Button
        onClick={onClick}
        type={"link"}
        size={"small"}
        style={{fontSize: 14}}
        className={'auxiliary-button'}
        disabled={disabled}
    >
        {children}
    </Button>
}


const NumericFilter = ({item, disabled=false}) => {
    return <>
        <Flex align={"center"}>
            <Form.Item
                name={`${item.name}.from`}
                style={{display: 'inline-block'}}
            >
                <InputNumber
                    controls={false}
                    placeholder={'e.g. ' + item.placeholder_left}
                    disabled={disabled}
                />
            </Form.Item>
            <span style={{margin: "0 8px"}}>-</span>
            <Form.Item
                name={`${item.name}.to`}
                style={{display: 'inline-block'}}
            >
                <InputNumber
                    controls={false}
                    placeholder={'e.g. ' + item.placeholder_right}
                    disabled={disabled}
                />
            </Form.Item>
        </Flex>
        {item.tips
            &&
            <Flex align={"center"} wrap={"wrap"} style={{margin: "4px 0 0 -8px"}}>
                {item.tips.map((tip, idx) => {
                    return <NumericFilterTipButton
                        key={idx}
                        name={item.name}
                        from={tip.from}
                        to={tip.to}
                        disabled={disabled}
                    >
                        {tip.label}
                    </NumericFilterTipButton>
                })}
            </Flex>
        }
    </>
}


export default NumericFilter;
