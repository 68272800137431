import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Button, DatePicker, message, Modal, Result, Typography, Upload} from 'antd';
import {CloudUploadOutlined, FileExcelOutlined} from "@ant-design/icons";
import {getWeeklyTypes} from "@API/upload";
import EditableFilesTable from "@components/data_import/EditableFilesTable";
import ImportedFilesTable from "@components/data_import/ImportedFilesTable";
import {useComponentsHandler} from "@hooks/ComponentsHandler";
import {useWebSocket} from "@hooks/WebSocketHook";
import {useStorage} from "@hooks/GlobalStorage";
import {Buffer} from 'buffer';
import dayjs from "dayjs";

const {Dragger} = Upload;

const TAG = "weekly";
const CONTROL_TAG = "weekly_control";

const DataImport = () => {
    const [weeklyTypes, setWeeklyTypes] = useState(null);
    const [filesContainer, setFilesContainer] = useState([]);
    const [verificationModal, setVerificationModal] = useState(false);
    const [verificationError, setVerificationError] = useState(null);
    const [date, setDate] = useState(dayjs().subtract(1, 'day'));
    // const [socketMessageModal, setSocketMessageModal] = useState(null);
    const transitContainer = useRef([]);

    const componentsHandler = useComponentsHandler();
    const {
        storage,
        dispatch,
        ACTION_TYPE_UPDATE,
        ACTION_TYPE_DELETE,
        ACTION_TYPE_INNER_UPDATE
    } = useStorage();
    const {
        sockets,
        createSocket,
        deleteSocket
    } = useWebSocket();

    const modifyFilesContainerData = newFilesData => {
        setFilesContainer(newFilesData);
        transitContainer.current = newFilesData;
    }

    useEffect(() => {
        getWeeklyTypes(setWeeklyTypes);
    }, [])

    useEffect(() => {
        return () => {
            if (sockets.hasOwnProperty(TAG) && sockets[TAG].readyState === sockets[TAG].OPEN) {
                if (storage[CONTROL_TAG]?.notificatorOpened) {
                    return
                }
                dispatch({type: ACTION_TYPE_INNER_UPDATE, tag: CONTROL_TAG, value: {notificatorOpened: true}});
            }
        }
    }, [sockets])

    useEffect(() => {
        if (filesContainer.length !== 0) {
            !storage.hasOwnProperty(CONTROL_TAG) && dispatch({type: ACTION_TYPE_UPDATE, tag: CONTROL_TAG, value: {}});
        }

        if (sockets.hasOwnProperty(TAG) && sockets[TAG].readyState === sockets[TAG].OPEN) {
            return
        }

        if (storage.hasOwnProperty(CONTROL_TAG) && storage[CONTROL_TAG]?.uploadingFinish === true) {
            return
        }

        const files = filesContainer.map(file => {
            return {
                type: file.type,
                name: file.file.name,
                progress: file.progress,
                status: file.status
            }
        });
        dispatch({type: ACTION_TYPE_UPDATE, tag: TAG, value: files});
    }, [filesContainer])

    const onDateSelect = useCallback((date) => {
        setDate(date);
    }, [setDate])

    const getUploadStatusAndText = () => {
        if (storage.hasOwnProperty(TAG) && storage[TAG].length !== 0) {
            const badlyProcessed = storage[TAG].find(file => {
                return file?.status != 'done'
            })
            if (badlyProcessed) {
                return {
                    title: 'Some of your files have problems!',
                    status: 'warning',
                    text: 'The data was processed by SPAS, however you seem to have bad files in the list. We recommend to skip the calculations. If you do not care about the wrong files, you can continue.'
                }
            }
            return {
                status: 'success',
                title: 'Successfully uploaded 3d-party files to the SPAS database!',
                text: '3d-party data files are in the DB now. If you click "Skip calculation", bi-weekly file will be calculated automatically by tomorrow. To calculate bi-weekly file on this data right now, click "Start calculation".'
            }
        } else {
            return {
                status: 'error',
                text: 'Something went completely wrong...',
                title: 'Houston, we have a problem!'
            }
        }
    }

    const disabledDateCallback = useCallback((currentDate) => {
        const today = new Date();
        const tooLate = currentDate.diff(today, 'days') >= 0;
        const tooEarly = currentDate.diff("1979-01-01", 'days') < 0;
        return tooEarly || tooLate
    }, [setDate])


    const filesPrepared = useMemo(() => {
        if (!storage.hasOwnProperty(TAG)) {
            return false
        }
        let unknownFiles = storage[TAG].filter(file => file.type === undefined);
        if (unknownFiles.length === 0 && date) {
            return true
        } else {
            return false
        }
    }, [date, storage])

    // console.log("filesPrepared: ", filesPrepared)

    const processFile = useCallback((file) => {
        if (weeklyTypes) {
            let trueType;
            for (let weeklyType of weeklyTypes) {
                let reType = new RegExp(weeklyType.replaceAll("_", " ").replace("empirical", "").trim(), 'gi');
                let searched = reType.exec(file.name.replaceAll("'", ""));
                if (searched) {
                    trueType = weeklyType;
                    break;
                }
            }

            if (filesContainer.filter(fileDetails => fileDetails.type === trueType && trueType !== undefined).length === 0) {
                transitContainer.current.push({
                    type: trueType,
                    file: file,
                    progress: 0,
                    status: "waiting"
                });
                setFilesContainer([...transitContainer.current]);
            } else {
                message.warning(`${trueType.replaceAll("_", " ").toUpperCase()} is already present in the list, remove it to proceed with this one.`);
            }
        }

        return true
    }, [filesContainer, weeklyTypes])

    const setSocketMessageModal = (data) => {
        dispatch({type: ACTION_TYPE_INNER_UPDATE, tag: CONTROL_TAG, value: {socketMessage: data}});
    }

    const setSocketFactorProcessingModal = (status) => {
        dispatch({type: ACTION_TYPE_INNER_UPDATE, tag: CONTROL_TAG, value: {factorsProcessingModal: status}});
    }

    const initSocket = () => {

        createSocket({
            url: process.env.REACT_APP_WEBSOCKET_ROOT + "/weekly_writer",
            tag: TAG,
            onmessageCallback: (event, socket) => {
                console.debug(event.data)
                let socketMessage = JSON.parse(event.data);
                if (socketMessage.hasOwnProperty("validation_error")) {
                    setSocketMessageModal({
                        message: socketMessage.validation_error,
                        type: socketMessage.type
                    })
                }
                if (socketMessage.hasOwnProperty("progress")) {
                    const index = storage[TAG].map((file, index) => {
                        if (socketMessage.type === file.type) {
                            return index
                        }
                    }).filter(value => (value !== null && value !== undefined))[0];
                    dispatch({
                        type: ACTION_TYPE_INNER_UPDATE,
                        tag: TAG,
                        index: index,
                        value: {progress: socketMessage.progress}
                    });
                }
                if (socketMessage.hasOwnProperty("warning")) {
                    const key = "warn";
                    message.warning({
                        content: socketMessage.warning,
                        duration: 10,
                        key: key,
                        onClick: () => message.destroy(key)
                    });
                }

                if (socketMessage.hasOwnProperty("error")) {
                    const key = "error";
                    message.warning({
                        content: socketMessage.error,
                        duration: 10,
                        key: key,
                        onClick: () => message.destroy(key)
                    });
                }
                if (socketMessage.hasOwnProperty("success")) {
                    const key = "succ";
                    message.success({
                        content: socketMessage.success,
                        duration: 10,
                        key: key,
                        onClick: () => message.destroy(key)
                    });
                }
                if (socketMessage.hasOwnProperty("factors_processing")) {
                    setSocketFactorProcessingModal(socketMessage['factors_processing']);
                }
                if (socketMessage.hasOwnProperty("status")) {
                    const index = storage[TAG].map((file, index) => {
                        if (socketMessage.type === file.type) {
                            return index
                        }
                    }).filter(value => (value !== null && value !== undefined))[0];
                    dispatch({
                        type: ACTION_TYPE_INNER_UPDATE,
                        tag: TAG,
                        index: index,
                        value: {status: socketMessage.status}
                    });
                }
            },
            onopenCallback: (event, socket) => {
                console.log(event)
                console.info('Socket opened!')
                const preparedFiles = filesContainer.map(file => {
                    const filePromise = new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.onloadend = e => {
                            var arrayBuffer = e.target.result;
                            resolve(Buffer.from(arrayBuffer))
                            // resolve(new Blob([arrayBuffer]))
                        }
                        reader.readAsArrayBuffer(file.file);
                    })

                    return filePromise.then(fileBinary => {
                        return {
                            file: fileBinary,
                            type: file.type
                        }
                    })
                });
                Promise.all(preparedFiles).then(files => {
                    let data = {
                        date: date,
                        files: files
                    };
                    const jsonData = JSON.stringify(data);
                    const blob = new Blob([jsonData], {type: "application/json"});
                    socket.send(blob);
                    dispatch({type: ACTION_TYPE_INNER_UPDATE, tag: CONTROL_TAG, value: {socketOpen: true}});
                })
            },
            onerrorCallback: error => {
                console.log(error)
                message.error(error)
            },
            oncloseCallback: event => {
                console.log(event)
                if (event.code > 1000) {
                    message.error("Data socket was closed abnormally with code " + event.code + ".")
                }
                dispatch({
                    type: ACTION_TYPE_INNER_UPDATE,
                    tag: CONTROL_TAG,
                    value: {socketOpen: false, uploadingFinish: true}
                });
            }
        });
    }

    const verifyAndSendFiles = () => {
        let unknownFiles = storage[TAG].filter(file => file.type === undefined);
        if (unknownFiles.length !== 0) {
            let err = "You have unknown file types";
            message.error(err);
            setVerificationError(err);
            return
        }
        if ((new Set(storage[TAG].map(file => file.type))).size !== storage[TAG].length) {
            let err = "You have duplicated file types, please resolve!";
            message.error(err);
            setVerificationError(err);
            return
        }
        if (!date) {
            let err = "The date is not set!";
            message.error(err);
            setVerificationError(err);
            return
        }
        onCloseVerificationModal();
        initSocket();
    }

    const onCloseVerificationModal = () => {
        setVerificationError(null);
        setVerificationModal(false);
    }

    const manualFinishFileUploading = () => {
        modifyFilesContainerData([]);
        dispatch({type: ACTION_TYPE_DELETE, tag: TAG});
        dispatch({type: ACTION_TYPE_DELETE, tag: CONTROL_TAG});
        deleteSocket(TAG);
        console.log("FULLY CLEAR")
    }

    // const checksocketOpen = () => {
    //     return storage.hasOwnProperty(CONTROL_TAG) && storage[CONTROL_TAG].hasOwnProperty("socketOpen") && storage[CONTROL_TAG].socketOpen
    // }

    // const checkSocketHaveMessage = () => {
    //     return storage.hasOwnProperty(CONTROL_TAG) && storage[CONTROL_TAG].hasOwnProperty("socketMessage") && storage[CONTROL_TAG].socketMessage
    // }

    const draggerProps = {
        multiple: true,
        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv",
        customRequest: () => {
        },
        beforeUpload: file => processFile(file),
        showUploadList: false
    };

    // console.log(storage[CONTROL_TAG])

    return (
        (<div>
            <Typography.Title level={4} className={"spas"}>Upload</Typography.Title>
            <Typography.Paragraph style={{whiteSpace: "pre-wrap"}}>
                1. Drop the files to the upload area below{"\n"}
                2. Check the date{"\n"}
                3. Upload and validate the data{"\n"}
                4. Start or skip data calculations{"\n"}
            </Typography.Paragraph>
            {/* <Typography.Paragraph>2. Upload and validate the data.</Typography.Paragraph>
            <Typography.Paragraph>3. Start the calculation task.</Typography.Paragraph> */}
            <div
                style={{
                    height: "250px",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "center",
                    cursor: !storage[CONTROL_TAG]?.socketOpen ? "default" : "not-allowed"
                }}
                className={'ant-upload ant-upload-drag'}
            >
                <Dragger {...draggerProps} style={{border: 0}} disabled={storage[CONTROL_TAG]?.socketOpen}>
                    <p className="ant-upload-drag-icon">
                        <FileExcelOutlined style={{color: "green"}}/>
                    </p>
                    <p className="ant-upload-text">Click or drag to upload</p>
                    <p className="ant-upload-hint">
                        Upload 3d-party data files
                    </p>
                </Dragger>
            </div>
            <div>
                <Typography.Paragraph>The file data will be stored with a following date set:
                    <DatePicker value={date} bordered={false} renderExtraFooter={() => 'extra footer'}
                                onChange={(date) => setDate(date)} allowClear={false}/>

                </Typography.Paragraph>

                {!storage[CONTROL_TAG]?.socketOpen && storage[CONTROL_TAG]?.uploadingFinish !== true ?
                    <EditableFilesTable files={filesContainer} changeFiles={modifyFilesContainerData}
                                        types={weeklyTypes}/>
                    :
                    <ImportedFilesTable/>
                }
            </div>
            {!storage[CONTROL_TAG]?.socketOpen
                && filesContainer.length !== 0 &&
                storage[CONTROL_TAG]?.uploadingFinish !== true && <div style={{marginTop: "10px"}}>
                    <Button type="primary" disabled={!filesPrepared}
                            onClick={() => verifyAndSendFiles()}><CloudUploadOutlined/>Upload and validate</Button>
                </div>}
            {storage[CONTROL_TAG]?.uploadingFinish === true && <div style={{marginTop: "10px"}}>
                <Button type="primary" onClick={() => manualFinishFileUploading()}>Thank you, dear robot, I am
                    done!</Button>
            </div>}
            <Modal
                title="File type verification"
                open={verificationModal}
                width={850}
                onCancel={() => onCloseVerificationModal()}
                footer={[
                    <Button key="back" onClick={() => onCloseVerificationModal()}>
                        Return to edit
                    </Button>,
                    <Button key="submit" type="primary" disabled={!filesPrepared} onClick={() => verifyAndSendFiles()}>
                        All right, let's go!
                    </Button>
                ]}
            >
                <div style={{display: "flex", flexDirection: "column"}}>
                    <span style={{fontSize: "1.2em"}}>Please, check the correctness of file types</span>
                    <span style={{fontSize: "1.2em", marginBottom: "15px"}}>You can not have unknown types, please, fix it if you have</span>
                    <ImportedFilesTable notShow={["progress"]}/>
                    <div style={{marginTop: "15px", display: "flex", flexDirection: "column"}}>
                        <span style={{fontSize: "1.2em"}}>Select date on which files will be uploaded</span>
                        <DatePicker defaultValue={date} style={{width: "200px"}} onChange={onDateSelect}
                                    disabledDate={disabledDateCallback}/>
                    </div>
                    {verificationError &&
                        <span style={{color: "#d60202", fontSize: "1.2em"}}>{verificationError}</span>}
                </div>
            </Modal>
            <Modal
                title="Validation message"
                open={storage[CONTROL_TAG]?.socketMessage ? true : false}
                onCancel={() => setSocketMessageModal(null)}
                width={850}
                maskClosable={false}
                closable={false}
                footer={[
                    <Button key="back" onClick={() => {
                        sockets[TAG].send(new Blob([false]));
                        setSocketMessageModal(null);
                    }}>
                        Skip this file
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => {
                        setSocketMessageModal(null);
                        sockets[TAG].send(new Blob([true]));
                    }}>
                        Continue
                    </Button>
                ]}
            >
                <div style={{display: "flex", flexDirection: "column"}}>
                    {storage[CONTROL_TAG]?.socketMessage &&
                        <Typography.Paragraph>{storage[CONTROL_TAG].socketMessage.message}</Typography.Paragraph>}
                </div>
            </Modal>
            <Modal title={false}
                   open={storage[CONTROL_TAG]?.factorsProcessingModal == 'question' ? true : false}
                   footer={null}
                   maskClosable={false}
                   width={850}
                   closable={false}
            >
                <Result
                    status={getUploadStatusAndText()['status']}
                    title={getUploadStatusAndText()['title']}
                    subTitle={getUploadStatusAndText()['text']}
                    maskClosable={false}
                    width={850}
                    closable={false}
                    footer={null}
                    extra={[
                        <Button type="primary" key="submit" onClick={() => {
                            setSocketFactorProcessingModal(false);
                            sockets[TAG].send(new Blob([true]));
                        }}>
                            Start calculation
                        </Button>,
                        <Button key="cancel" onClick={() => {
                            sockets[TAG].send(new Blob([false]));
                            setSocketFactorProcessingModal(false);
                            manualFinishFileUploading()
                        }}>Skip calculation</Button>,
                    ]}
                />
            </Modal>
            <Modal title={false}
                   open={storage[CONTROL_TAG]?.factorsProcessingModal == 'error' ? true : false}
                   footer={null}
                   maskClosable={false}
                   width={850}
                   closable={false}
            >
                <Result
                    status={'error'}
                    title={'Running the calculation task failed!'}
                    subTitle={'We have problems running the calculations for your new data. The dev team should be already notified and work hard on the problem solving.'}
                    maskClosable={false}
                    width={850}
                    closable={false}
                    footer={null}
                    extra={[
                        <Button key="cancel" onClick={() => {
                            setSocketFactorProcessingModal(false);
                            manualFinishFileUploading()
                        }}>That is sad</Button>,
                    ]}
                />
            </Modal>
            <Modal title={false}
                   open={storage[CONTROL_TAG]?.factorsProcessingModal == 'success' ? true : false}
                   footer={null}
                   maskClosable={false}
                   width={850}
                   closable={false}
            >
                <Result
                    status={'success'}
                    title={'The calculation task was run successfully!'}
                    subTitle={'Now your data is being processed by SPAS and merged with other Signet data to calculate factor values. ' +
                        'This operation usually takes about four hours, so you can leave the page and download the file in 4-5 hours. We will also try to reach you by email when it is done.'}
                    maskClosable={false}
                    width={850}
                    closable={false}
                    footer={null}
                    extra={[
                        <Button type={'primary'} key="ok" onClick={() => {
                            manualFinishFileUploading()
                        }}>Thank you</Button>,
                    ]}
                />
            </Modal>
        </div>)
    );
}

export default DataImport
