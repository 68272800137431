/* eslint-disable react/prop-types */
import React from 'react';
import {Card, Flex, Progress, theme,} from 'antd';
import {grey} from '@ant-design/colors';
import {is_null} from "@global/ValueFormatter";
import LockTooltip from "@components/retail/cards/LockTooltip";
import {LockFilled} from "@ant-design/icons";


const {useToken} = theme;


const StatsCard = ({
                       title,
                       value,
                       valueColor = null,
                       progress = null,
                       progressColor = null,
                       wrapTitle = true,
                       locked = false,
                       style = {}
                   }) => {
    const {token} = useToken();

    return <Card
        type={"inner"}
        style={{
            borderColor: "#e3e3e3",
            background: "rgb(248, 248, 248)",
            ...style
        }}
    >
        <Flex vertical align={"center"}>
            <Flex align={"center"}>
                {!locked && progress
                    &&
                    <Progress
                        percent={progress}
                        steps={5}
                        showInfo={false}
                        strokeColor={progressColor}
                        size={[3, 14]}
                        style={{marginRight: "8px"}}
                    />
                }
                <span style={{
                    fontSize: 20,
                    lineHeight: 1,
                    fontWeight: 500,
                    color: valueColor ?? 'rgba(0, 0, 0, 0.8)',
                }}>
                    {locked
                        ? <LockTooltip label={title} stuff={"data"}>
                            <LockFilled style={{color: token.colorTextDescription}}/>
                        </LockTooltip>
                        : (is_null(value) ? "-" : value)
                    }
                </span>
            </Flex>
            <span style={{
                fontSize: 14,
                color: grey[4],
                marginTop: 4,
                textAlign: "center",
                lineHeight: 1.2,
                whiteSpace: wrapTitle ? "none" : "nowrap",
            }}>
                {title}
            </span>
        </Flex>
    </Card>
}


export default StatsCard;
