import React, {useRef, useState} from "react";
import {Button, Flex, Input, Popover, theme} from "antd";
import {SendOutlined} from "@ant-design/icons";
import {useAiChatContext} from "@hooks/AiChatContext";
import {useAuth} from "@hooks/AuthHook";
import {
    RequestLockModal
} from "@components/retail/cards/LockTooltip";


const {useToken} = theme;


const AiChatInput = ({}) => {
    const {requestLockInfo} = useAuth();
    const {sendGAEvent} = useAuth();
    const {
        sendMessage,
        waitingForResponse,
        predefinedMessages,
        getPredefinedMessageByKey
    } = useAiChatContext();
    const [lockInfo, setLockInfo] = useState(null);
    const [openLockModal, setOpenLockModal] = useState(false);
    const [currentMessage, setCurrentMessage] = useState('');
    const searchInputRef = useRef(null);
    const {token} = useToken();

    const loadPredefinedMessage = (key) => {
        let msg = getPredefinedMessageByKey(key);
        setCurrentMessage(msg);
        searchInputRef.current.focus();
        return msg
    }

    return <Flex vertical gap={"small"} style={{
        background: token.colorBgGreyDark,
        padding: token.paddingXS,
        borderTop: `1px solid rgb(220, 220, 220)`,
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
    }}>
        {/*<Flex>*/}
        {/*    {predefinedMessages.slice(0, 3).map((obj => {*/}
        {/*        return <Button*/}
        {/*            key={obj.key}*/}
        {/*            type={"text"}*/}
        {/*            size={"small"}*/}
        {/*            onClick={() => {*/}
        {/*                loadPredefinedMessage(obj.key);*/}
        {/*                sendGAEvent({*/}
        {/*                    category: 'AI Chat Input',*/}
        {/*                    action: 'Click',*/}
        {/*                    label: obj.key*/}
        {/*                });*/}
        {/*            }}*/}
        {/*            style={{*/}
        {/*                fontSize: token.fontSizeSM,*/}
        {/*                textTransform: "lowercase"*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            {obj.label}*/}
        {/*        </Button>*/}
        {/*    }))}*/}
        {/*    <Popover*/}
        {/*        content={*/}
        {/*            <Flex vertical gap={"small"}>*/}
        {/*                {predefinedMessages.slice(3).map((obj) => {*/}
        {/*                    return <Button*/}
        {/*                        key={obj.key}*/}
        {/*                        type={"text"}*/}
        {/*                        size={"small"}*/}
        {/*                        onClick={() => {*/}
        {/*                            loadPredefinedMessage(obj.key);*/}
        {/*                            sendGAEvent({*/}
        {/*                                category: 'AI Chat Input',*/}
        {/*                                action: 'Click',*/}
        {/*                                label: obj.key*/}
        {/*                            });*/}
        {/*                        }}*/}
        {/*                        style={{*/}
        {/*                            fontSize: token.fontSizeSM,*/}
        {/*                            textTransform: "lowercase"*/}
        {/*                        }}*/}
        {/*                    >*/}
        {/*                        {obj.label}*/}
        {/*                    </Button>*/}
        {/*                })}*/}
        {/*            </Flex>*/}
        {/*        }*/}
        {/*        placement="topRight"*/}
        {/*    >*/}
        {/*        <Button*/}
        {/*            type={"text"}*/}
        {/*            size={"small"}*/}
        {/*            style={{*/}
        {/*                fontSize: token.fontSizeSM,*/}
        {/*                textTransform: "lowercase"*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            more...*/}
        {/*        </Button>*/}
        {/*    </Popover>*/}
        {/*</Flex>*/}
        <Flex gap={"small"}>
            <Input.TextArea
                placeholder={'Write you question to Holi'}
                value={currentMessage}
                variant={"borderless"}
                size={"large"}
                autoSize={{minRows: 1}}
                autoFocus={true}
                ref={searchInputRef}
                disabled={waitingForResponse}
                onChange={(e) => setCurrentMessage(e.target.value)}
                onPressEnter={(e) => {
                    e.preventDefault();
                    const info = requestLockInfo("#post-ai-conversation");
                    if (info.locked) {
                        setLockInfo(info);
                        setOpenLockModal(true);
                    } else {
                        sendMessage(e.target.value);
                        sendGAEvent({
                            category: 'AI Chat Input',
                            action: 'Click',
                            label: 'Send message on press Enter'
                        });
                        setCurrentMessage("");
                    }
                }}
                style={{
                    background: "white",
                    borderRadius: token.borderRadius,
                    // borderRadius: 36,
                    fontSize: token.fontSize,
                }}
            />
            <Button
                type={"primary"}
                icon={<SendOutlined style={{
                    fontSize: token.fontSizeSM,
                    rotate: "-30deg"
                }}/>}
                disabled={waitingForResponse}
                loading={waitingForResponse}
                onClick={() => {
                    const info = requestLockInfo("#post-ai-conversation");
                    if (info.locked) {
                        setLockInfo(info);
                        setOpenLockModal(true);
                    } else {
                        sendMessage(currentMessage);
                        sendGAEvent({
                            category: 'AI Chat Input',
                            action: 'Click',
                            label: 'Send message on button click'
                        });
                        setCurrentMessage("");
                    }
                }}
                style={{
                    borderRadius: token.borderRadius,
                    // borderRadius: 36,
                    minWidth: 36,
                    minHeight: 36,
                }}
            />
        </Flex>
        <RequestLockModal
            limitMessage={"You exceeded the limit of using AI chat."}
            lockMessage={"AI chat is not available for your current subscription."}
            lockedUntil={lockInfo?.until}
            open={openLockModal}
            setOpen={setOpenLockModal}
        />
    </Flex>
}

export default AiChatInput;
