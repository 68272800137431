import axiosInstance from "@API/APIClient";

const get_subscription_options = async () => {
    let url = `/subscription/available_subscriptions`
    return await axiosInstance.get(url)
}

const start_pay_session = async (price_id) => {
    let url = `/subscription/create-stripe-session`
    if (!price_id) {
        url = '/subscription/create-portal-session';
        console.log('running pure portal link.')
    } else {
        console.log('running for price id ' + price_id)
    }

    return await axiosInstance.get(url, {params: {price_id: price_id}})
}

const get_test_settings = async (email) => {
    let url = `/subscription/get-test-settings`
    return await axiosInstance.post(url,  email)
}

const update_test_settings = async (settings) => {
    let url = `/subscription/update-test-settings`
    return await axiosInstance.post(url, settings)
}



export {
    get_subscription_options,
    start_pay_session,
    get_test_settings,
    update_test_settings
}