export const RETAIL_SUBSCRIPTION_PLANS = [
    {
        key: "free",
        title: "Free",
        price: 0,
        description: "For investors who start researching the market",
        features: [
            "Macroeconomic dashboard with AI comments",
            "Stock & fund screener",
            "Models marketplace"
        ],
    },
    {
        key: "base",
        title: "Base",
        price: 20,
        description:
            "For investors who want to manage their portfolios " +
            "with advanced analytical tools and AI assistance",
        features: [
            "Macroeconomic dashboard with AI comments",
            "Stock & fund screener",
            "Models marketplace",
            "Stock and ETF ratings",
            "Portfolio analytics with AI comments",
            "Portfolio review",
            "Stress tests",
            "Risk profile",
            "Stress tests",
            "Positions analytics",
            "AI chat with 20 free requests",
        ],
    }, {
        key: "plus",
        title: "Plus",
        price: 20,
        description:
            "For investors who want extensive AI assistance in managing " +
            "their portfolios",
        features: [
            "Macroeconomic dashboard with AI comments",
            "Stock & fund screener",
            "Models marketplace",
            "Stock and ETF ratings",
            "Portfolio analytics with AI comments",
            "Portfolio review",
            "Stress tests",
            "Risk profile",
            "Stress tests",
            "Retirement map",
            "Positions analytics",
            "AI chat with 30 free requests",
        ],
    },
    {
        key: "pro",
        title: "Pro",
        price: 30,
        description:
            "For professional investors and institutions seeking comprehensive AI-driven portfolio management, " +
            "advanced risk analysis, and exclusive market insights",
        features: [
            "Macroeconomic dashboard with AI comments",
            "Stock & fund screener",
            "Models marketplace",
            "Stock and ETF ratings",
            "Portfolio analytics with AI comments",
            "Portfolio review",
            "Stress tests",
            "Risk profile",
            "Stress tests",
            "Retirement map",
            "Positions analytics",
            "AI chat with 100 free requests",
            "AI with deep knowledge of assets",
            "Configurable AI character",
        ],
    }
];

export const RETAIL_SUBSCRIPTION_PLANS_DICT = {
    "free": RETAIL_SUBSCRIPTION_PLANS[0],
    "base": RETAIL_SUBSCRIPTION_PLANS[1],
    "plus": RETAIL_SUBSCRIPTION_PLANS[2],
    "pro": RETAIL_SUBSCRIPTION_PLANS[3],
}
