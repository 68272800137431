import React, {useState} from "react";
import {
    Button,
    Card,
    Col,
    Row,
    Spin,
    theme,
    Typography
} from "antd";
import {Line} from "@ant-design/charts";
import {useRequest, useUpdateEffect} from "ahooks";
import dayjs from "dayjs";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {getMacroHistorySeparate} from "@API/ahooks/dashboard";
import Localizator from "@global/Localizator";
import NestedCard from "@components/retail/cards/NestedCard";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import HRadioGroup from "@holistico/aux/HRadioGroup";
import {useAuth} from "@hooks/AuthHook";


const {useToken} = theme;


const COLORS = [
    'rgb(255, 127, 14)',  // safety orange
    'rgb(31, 119, 180)',  // muted blue
    'rgb(44, 160, 44)',   // cooked asparagus green
    'rgb(214, 39, 40)',   // brick red
    'rgb(148, 103, 189)', // muted purple
    'rgb(140, 86, 75)',   // chestnut brown
    'rgb(227, 119, 194)', // raspberry yogurt pink
    'rgb(127, 127, 127)', // middle gray
    'rgb(188, 189, 34)',  // curry yellow-green
    'rgb(23, 190, 207)',  // blue-teal
]


const MacroChart = ({data, color, height}) => {
    const {token} = useToken();

    const config = {
        data: data,
        xField: 'date',
        yField: 'value',
        seriesField: 'column_name',
        color: [color],
        height: height,
        animate: false,
        xAxis: {
            type: 'timeCat',
            tickCount: 5,
            label: {
                formatter: (v) => dayjs(v).format('YYYY')
            },
        },
        legend: {
            position: 'bottom-left',
            layout: 'vertical',
            flipPage: false,
            offsetY: 8,
            maxItemWidth: 500,
            itemName: {
                 formatter: (column_name) => (Localizator.t(`factor.${column_name}`))
            }
        },
        tooltip: {
            title: (title) => {
                return dayjs(title).format('MMMM YYYY');
            },
            formatter: (datum) => {
                return {
                    name: Localizator.t(`factor.${datum.column_name}`),
                    value: is_null(datum.value) ? "-" : ValueFormatter.number(datum.value)
                }
            }
        },
        annotations: data.length === 0 ? [] : [
            /*{
                type: 'regionFilter',
                start: ['min', 0],
                end: ['max', 'min'],
                color: 'rgba(214,42,13,0.71)',
            },*/
            {
                type: 'region',
                start: ['1981-05-31', 'min'],
                end: ['1982-12-31', 'max'],
            }, {
                type: 'region',
                start: ['1991-01-31', 'min'],
                end: ['1991-12-31', 'max'],
            }, {
                type: 'region',
                start: ['2001-03-31', 'min'],
                end: ['2002-01-31', 'max'],
            }, {
                type: 'region',
                start: ['2001-03-31', 'min'],
                end: ['2002-01-31', 'max'],
            }, {
                type: 'region',
                start: ['2007-12-31', 'min'],
                end: ['2009-05-31', 'max'],
            }, {
                type: 'region',
                start: ['2019-11-30', 'min'],
                end: ['2020-11-30', 'max'],
            }, {
                type: 'line',
                start: ['min', 0],
                end: ['max', 0],
                style: {
                    stroke: token.colorTextDescription,
                    lineOpacity: 0.5,
                    lineWidth: 2
                }
            }],
    };

    return <Line {...config}/>;
}


const MacroChartCard = ({
                       title,
                       columns,
                       fromDate = "1976-01-31",
                       defaultColumn = '',
                       height = 250
                   }) => {
    const [data, setData] = useState({});
    const [selected, setSelected] = useState(defaultColumn);
    const [openDrawer, setOpenDrawer] = useState(false);
    const {token} = useToken();
    const {sendGAEvent} = useAuth();

    const options = columns.map(column => ({
        label: Localizator.t(`factor.short.${column}`),
        value: column
    }));

    const {
        data: response,
        loading,
        error
    } = useRequest(() => getMacroHistorySeparate(columns, fromDate));

    useUpdateEffect(() => {
        if (error) {
            console.error(error.message)
        } else {
            console.error('Error while loading macroeconomic data')
        }
    }, [error])

    useUpdateEffect(() => {
        console.debug("MacroChartCard data loaded", response.data);
        if (response.data) {
            for (const column in response.data) {
                // Add gap from the right of the x-axis
                if (response.data[column].values && response.data[column].values.length > 0) {
                    [...Array(6).keys()].map(shift => {
                        response.data[column].values.push({
                            value: null,
                            'date': dayjs().add(shift + 1, 'month').endOf('month').format("YYYY-MM-DD")
                        })
                    })
                }
                // TODO: remove old ai comments
            }
        }
        setData(response.data ?? {});
    }, [response])



    return <Card hoverable style={{
        height: "100%",
        background: token.colorBgGrey,
        borderColor: "rgb(220, 220, 220)",
        cursor: "default",
    }}>
        <NestedCard background={"white"}>
            {title
                && <Typography.Title level={4} style={{
                    marginTop: 0,
                    marginBottom: token.paddingXXS
                }}>
                    {title}
                </Typography.Title>
            }
            {columns.length > 1
                && <HRadioGroup
                    options={options}
                    value={selected}
                    onChange={(value) => {
                        setSelected(value);
                        sendGAEvent({
                            category: 'Macro Chart',
                            action: 'Click',
                            label: value
                        });
                    }}
                />
            }
        </NestedCard>
        <Spin spinning={loading}>
            <Row gutter={[token.paddingXL, token.paddingLG]}>
                <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                    <MacroChart
                        data={selected in data ? data[selected]?.values : []}
                        color={COLORS[columns.indexOf(selected)]}
                        height={height}
                    />
                </Col>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    {data && Object.keys(data).length > 0 &&
                        <>
                            <Typography.Title level={5} style={{
                                marginTop: 0,
                                marginBottom: token.paddingXS,
                            }}>
                                Holi's comment
                            </Typography.Title>
                            {selected in data && data[selected]?.ai_comment?.comment
                                ? <>
                                    <Typography.Paragraph
                                        ellipsis={{rows: 8}}
                                        style={{marginBottom: 0}}
                                    >
                                        {data[selected]?.ai_comment?.comment}
                                    </Typography.Paragraph>
                                    <Button
                                        type={"link"}
                                        onClick={() => setOpenDrawer(true)}
                                        style={{padding: 0}}
                                    >
                                        Read more...
                                    </Button>
                                </>
                                : <Typography.Paragraph>
                                    Holi's comment not found
                                </Typography.Paragraph>
                            }
                        </>
                    }
                </Col>
            </Row>
        </Spin>
        <MobileDrawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            width={600}
        >
            <Card>
                <Typography.Title level={4} style={{margin: 0}}>
                    {Localizator.t(`factor.${selected}`)}
                </Typography.Title>
                <Typography.Title level={5}>
                    Holi's comment
                </Typography.Title>
                {selected in data && data[selected]?.ai_comment?.comment
                    ? data[selected]?.ai_comment?.comment.split("\n").map((par, index) => {
                        return <Typography.Paragraph key={index}>
                            {par}
                        </Typography.Paragraph>
                    })
                    : <Typography.Paragraph>
                        Holi's comment not found
                    </Typography.Paragraph>
                }
            </Card>
        </MobileDrawer>
    </Card>
}

export default MacroChartCard;
