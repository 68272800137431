import axiosInstance from "@API/APIClient";
import axios from "axios";

let cancel;

const listProxies = (callback) => {
    let searchAssetUrl = `/assets/proxy`;
    axiosInstance.get(searchAssetUrl,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            callback(data);
        })
        .catch((response) => {
            callback(response, true)
        })
}

const searchAssets = (searchString, tickerOnly, searchDoneHandler) => {
    let searchAssetUrl = `/assets/search?search_str=${encodeURIComponent(searchString)}`;

    if (!tickerOnly) {
        searchAssetUrl = `/assets/search_everywhere?search_str=${encodeURIComponent(searchString)}`;
    }
    axiosInstance.get(searchAssetUrl,
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(({data}) => {
            // console.log("searchAssets", data)
            searchDoneHandler(data);
        })
        .catch(({response}) => {
            if (response) {
                searchDoneHandler(response, true);
            }
        })
}

const getDetails = (asset, callback) => {
    axiosInstance.post('/assets/details', asset)
        .then(({data}) => {
            callback(data);
        })
        .catch((response) => {
            callback(response, true);
        })
};

const asyncGetExactAssetByTicker = async (ticker, abortSignal) => {
    let url = `/assets/exact-ticker?ticker=${ticker}`
    //let url = `/screener/company?search-pinned=${ticker}`
    return await axiosInstance.get(url, {signal: abortSignal})
}

const getEtfData = (callback) => {
    axiosInstance.get('/component/etf')
        .then(({data}) => {
            callback(data);
        })
        .catch(({response}) => {
            callback(response, true);
        })
}

const describeWithAi = (asset, callback) => {
    axiosInstance.post('/assets/describe_with_ai', asset)
        .then(({data}) => {
            callback(data);
        })
        .catch((response) => {
            callback(response, true);
        })
};

export
{
    searchAssets,
    listProxies,
    getDetails,
    getEtfData,
    describeWithAi,
    asyncGetExactAssetByTicker,
    cancel
}
