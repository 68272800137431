import React, {useState} from "react";
import {message, Spin, Typography, notification} from 'antd';
import {useRequest, useUpdateEffect, useWebSocket} from "ahooks";
import {ReadyState} from "ahooks/lib/useWebSocket";
import {useAuth} from "@hooks/AuthHook";
import moment from "moment";
import {getDefaultsAsync} from "@API/backtest";
import {BacktestContext} from "@components/backtest/BacktestContext";
import BacktestLoadingModal from "@components/backtest/BacktestLoadingModal";
import BacktestConfiguration from "@components/backtest/BacktestConfiguration";
import BacktestStatistics
    from "@components/backtest/summary/BacktestStatistics";
import BacktestAggregator
    from "@components/backtest/detailed/BacktestAggregator";


const BACKTEST_SOCKET_URL = process.env.REACT_APP_WEBSOCKET_ROOT + "/backtest"


const Backtest = ({}) => {
    const [actualConfig, setActualConfig] = useState(null);
    const [operationLogs, setOperationLogs] = useState([]);
    const [statistics, setStatistics] = useState(null);
    const [aggregationResults, setAggregationResults] = useState([]);
    const {sendGAEvent} = useAuth();

    const auth = useAuth();
    const [notificationApi, notificationContextHolder] = notification.useNotification();
    const [keepAlive, setKeepAlive] = useState(setInterval(() => sendKeepAlive(), 13000));
    const {data: defaults} = useRequest(getDefaultsAsync);

    const {sendMessage, latestMessage, connect, readyState} = useWebSocket(
        BACKTEST_SOCKET_URL,
        {
            manual: false,
            onError: (error) => {
                message.error(error.message);
            },
            onClose: () => {
                console.warn("socket connection closed");
            }
        }
    )

    useUpdateEffect(() => {
        if (latestMessage.data) {
            let socketMessage = eval("(" + latestMessage.data + ")");
            processSocketMessage(socketMessage);
        }
    }, [latestMessage])

    const sendJson = (msg_json) => {
        if (readyState !== ReadyState.Open) {
            connect();
        }
        sendMessage(JSON.stringify(msg_json), {type: "application/json"});
    }

    const clearOperationLogs = () => {
        setOperationLogs([]);
    }

    const sendKeepAlive = () => {
        let keepAliveRequest = {
            operation: 'keepalive',
            config: {user: auth?.user?.email}
        }
        if (readyState === ReadyState.Open) {
            sendJson(keepAliveRequest);
        } else {
            clearInterval(keepAlive);
        }
    }

    const processSocketMessage = (json) => {
        let {operation, status, text, result, warnings} = json;

        if (operation === 'run') {
            let curTime = moment().format('HH:MM:ss');
            let newLog = {
                status: status,
                text: (curTime + ": " + text),
                key: text
            };
            setOperationLogs((operationLogs) => [...operationLogs, newLog]);

            if (status === 'success') {
                processRunResults(result);
            } else if (status === 'error') {
                message.error(result);
            }
            if (warnings && warnings.length > 0) {
                for (let warning of warnings) {
                    notificationApi.warning({
                        message: "Calculation warning",
                        description: warning,
                        duration: 0,
                        placement: 'topRight'
                    });
                }
            }
        }

        if (operation === 'load') {
            if (status === 'success') {
                processAggregationResults(result);
            } else {
                message.error(result);
                processAggregationResults([]);
            }
        }
    }

    const processRunResults = (runResults) => {
        console.debug("Run results", runResults);
        setStatistics(runResults);
        clearOperationLogs();
    }

    const processAggregationResults = (aggResults) => {
        console.debug("Aggregation results", aggResults);
        setAggregationResults(aggResults);
    }

    const clearResults = () => {
        setStatistics(null);
        setAggregationResults([]);
    }

    const runCalculations = (config) => {
        sendGAEvent({
            category: 'Backtest',
            action: 'Click',
            label: 'Start testing'
        });
        console.log('running the calculations');
        console.debug("Calculation config", config);
        clearOperationLogs();
        sendJson({operation: 'run', config: config});
        setActualConfig(structuredClone(config));
    }

    const runAggregation = (config) => {
        console.log('running the aggregation');
        console.debug("Aggregation config", config);
        const jsonData = {operation: 'load', config: config};
        sendJson(jsonData);
    }

    return <>
        <Typography.Title level={3}>
            Backtest
        </Typography.Title>
        <Spin size={"large"} spinning={!defaults} tip={"Preparing the ui..."}>
            {defaults
                &&
                <BacktestContext.Provider value={{...defaults.data}}>
                    <BacktestConfiguration
                        run={(config) => runCalculations(config)}
                        clearResults={clearResults}
                    />
                </BacktestContext.Provider>
            }
            {statistics !== null
                &&
                <BacktestStatistics
                    statistics={statistics}
                    config={actualConfig}
                />
            }
            {statistics !== null
                &&
                <BacktestAggregator
                    config={actualConfig}
                    run={runAggregation}
                    rows={aggregationResults}
                />
            }
        </Spin>
        <BacktestLoadingModal
            messages={operationLogs}
            clearMessages={clearOperationLogs}
        />
        {notificationContextHolder}
    </>
}

export default Backtest
