/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Radar} from "@ant-design/charts";
import ChartStyles from "@global/ChartStyles";
import {Empty} from "antd/es";
import {G2} from "@antv/g2plot";

G2.registerInteraction('hover-cursor', {
    showEnable: [
        {trigger: 'element:mouseenter', action: 'cursor:pointer'},
        {trigger: 'element:mouseleave', action: 'cursor:default'},
    ],
});

const RedFlagHealthRadar = ({flags_healths, updateDetails}) => {
    const [config, setConfig] = useState({
        data: [],
        xField: 'label',
        yField: 'value'
    });

    useEffect(() => {
        if (!flags_healths) {
            return
        }

        const chartConfig = {
            title: 'Wealth Health',
            data: flags_healths,
            xField: 'label',
            yField: 'value',
            padding: 12,
            appendPadding: 12,
            meta: {
                value: {
                    alias: 'Wealth Health',
                    min: 0,
                    max: 110
                },
            },
            xAxis: {
                line: null,
                tickLine: null,
                grid: {
                    line: {
                        style: {
                            lineDash: null,
                        },
                    },
                },
                label: {
                    style: {
                        //fill: 'black'
                    },
                    offset: 10
                }
            },
            yAxis: {
                line: null,
                tickLine: null,
                grid: {
                    line: {
                        type: 'line',
                        style: {
                            lineDash: [4, 4],
                        },
                    },
                },
                label: null
            },
            area: {
                style: {
                    fill: '#52c41a'
                }
            },
            point: {
                style: (datum) => {
                    let status = ChartStyles.get_status(datum.value, 85, 95)
                    let color = ChartStyles.get_status_color(status)
                    return {
                        lineWidth: 0,
                        fill: color,
                        r: 7,
                        opacity: 0.8
                    }
                }
            },
            lineStyle: {
                lineDash: [2, 2],
                stroke: ChartStyles.colors[1]
            },
            tooltip: {
                showMarkers: false
            },
            interactions: [{type: 'hover-cursor'}]
        }
        setConfig(chartConfig)
    }, [flags_healths])

    return <div style={{padding: 12, minHeight: 350}}>
        {flags_healths ?
            <Radar {...config}
                   onEvent={(plot, event) => {
                       //'plot:click', 'element:mouseenter',
                       if (['area:mousemove'].includes(event.type)) {
                           const data = event.data.data
                           if (Array.isArray(data)) {
                               console.log('plot:click:array')
                               const {x, y} = event;
                               const tooltipData = plot.chart.getTooltipItems({x, y});
                               if (tooltipData) {
                                   let tooltip = tooltipData[0]
                                   updateDetails(tooltip.data)
                               }
                           } else {
                               updateDetails(data)
                           }
                       } else if (event.type == 'plot:mouseleave') {
                           updateDetails(null)
                       }
                   }}/> : <Empty description={"Waiting for data..."}/>}
    </div>
}

export default RedFlagHealthRadar;
