import axiosInstance from "../APIClient";

const getProposal = async (id) => {
    let url = `/portfolio/model?full=false`
    return await axiosInstance.get(`client/${id}`)
};

const getAiThoughts = async (clientId, type, forProposed=false) => {
    if (type === 'json') {
        return await getAiJson(clientId, forProposed)
    }
    let url = `/client/ai/${type}/${clientId}`;
    let params = {};
    if (forProposed) {
        params.for_proposed = forProposed;
    }
    return await axiosInstance.get(url, {params: params})
}

const getAiJson = async (id, forProposed=false) => {
    let url = `/ai-conversation/structured_comments/${id}`;
    let params = {};
    if (forProposed) {
        params.for_proposed = forProposed;
    }
    return await axiosInstance.get(url, {params: params})
}

export {getProposal, getAiJson, getAiThoughts}
