import React, {useRef} from "react";
import {Col, Flex, Row, theme} from "antd";
import ClientChat from "@holistico/proposal/view/ai/ClientChat";
import ConfigurationForm from "@holistico/proposal/view/ai/ConfigurationForm";
import {useSize} from "ahooks";


const {useToken} = theme;


const ConfiguredChat = ({}) => {
    const ref = useRef(null);
    const size = useSize(ref);

    const smallScreen = size?.width < 1000;

    return <Flex ref={ref} gap={"middle"} align={"flex-start"}>
        <ClientChat showConfigButton={smallScreen} style={{flexGrow: 2, flexBasis: 0}}/>
        {!smallScreen && <ConfigurationForm style={{flexGrow: 1, flexBasis: 0}}/>}
    </Flex>

}

export default ConfiguredChat;
