import React, {useEffect, useMemo, useState} from "react";
import {
    Button,
    Card,
    Divider, Flex,
    Form,
    message,
    Radio, Result,
    Select,
    Switch,
    Tabs,
    theme,
} from "antd";
import {BugFilled} from "@ant-design/icons";
import CardHeader from "@components/retail/cards/CardHeader";
import {useAuth} from "@hooks/AuthHook";
import Editor from "@monaco-editor/react";
import {useRequest} from "ahooks";
import {
    get_test_settings,
    update_test_settings
} from "@API/ahooks/subscriptions";
import {get_all_users_emails} from "@API/ahooks/users";
import NestedCard from "@components/retail/cards/NestedCard";


const {useToken} = theme;


const TestSettings = ({}) => {
    const {user, isAdmin} = useAuth();
    const [form] = Form.useForm();
    const enabled = Form.useWatch("enabled", form);
    const subscriptionKey = Form.useWatch("subscription_key", form);
    const userEmail = Form.useWatch("user_email", form);
    const [emails, setEmails] = useState([]);
    const {token} = useToken();

    const {run: getUsersEmails} = useRequest(get_all_users_emails, {
        manual: true,
        onSuccess: (result) => {
            setEmails(result.data);
        },
        onError: (error) => {
            console.error('Error while getting user emails', error);
        },
    });

    const {run: getSettings} = useRequest(get_test_settings, {
        manual: true,
        onSuccess: (result) => {
            form.setFieldsValue(result.data);
            console.log("getSettings", result.data)
        },
        onError: (error) => {
            console.error('Error while getting test settings', error);
        },
    });


    const {run: updateSettings, loading: updatingSettings} = useRequest(update_test_settings, {
        manual: true,
        onSuccess: (result) => {
            message.success("Subscription test settings saved!")
        },
        onError: (error) => {
            message.success("Error while updating test settings!")
            console.error('Error while updating test settings', error);
        },
    });

    const onFinish = (values) => {
        updateSettings(values);
    }

    useEffect(() => {
        if(userEmail) {
            getSettings(userEmail);
        }
    }, [userEmail]);

    useEffect(() => {
        getUsersEmails();
    }, []);

    return <Card style={{
        cursor: "default",
        maxWidth: 680,
        marginLeft: "auto",
        marginRight: "auto",
        background: token.colorBgGrey,
        borderColor: "rgb(220, 220, 220)"
    }}>
        {isAdmin()
            ? <Form
                form={form}
                onFinish={onFinish}
                layout={"vertical"}
                initialValues={{user_email: user.email}}
            >
                <NestedCard background={"white"}>
                    <CardHeader title={"Subscription test settings"}
                                icon={<BugFilled/>}/>
                    <Divider style={{marginTop: 0}}/>
                    <Form.Item name={"user_email"} style={{marginBottom: 0}}>
                        <Select
                            showSearch
                            optionFilterProp="label"
                            options={emails.map(item => ({
                                label: item,
                                value: item
                            }))}
                            allowClear={false}
                            style={{minWidth: 360, width: "fit-content"}}
                        />
                    </Form.Item>
                </NestedCard>
                <Form.Item name={"enabled"} label={<b>Use test settings</b>}>
                    <Switch checkedChildren="Yes" unCheckedChildren="No"/>
                </Form.Item>
                <Form.Item name={"subscription_key"}
                           label={<b>Subscription</b>}>
                    <Radio.Group
                        disabled={!enabled}
                        style={{display: "flex", flexDirection: "row"}}
                        options={["Custom", "Free", "Base", "Plus", "Pro"].map(
                            label => ({
                                label: label,
                                value: label.toLowerCase()
                            })
                        )}
                    />
                </Form.Item>
                <div
                    style={enabled ? {} : {filter: "opacity(50%) grayscale(100%)"}}>
                    <Form.Item
                        name={"custom_subscription"}
                        hidden={subscriptionKey !== "custom"}
                        style={{marginTop: token.marginXS,}}
                    >

                        <Editor
                            height="180px"
                            defaultLanguage="json"
                            options={{readOnly: !enabled}}
                        />

                    </Form.Item>
                </div>
                <div
                    style={enabled ? {} : {filter: "opacity(50%) grayscale(100%)"}}>
                    <Form.Item
                        name={"statistics"}
                        label={<b>Statistics</b>}
                        style={{marginTop: token.marginXS,}}
                    >
                        <Editor
                            height="180px"
                            defaultLanguage="json"
                            options={{readOnly: !enabled}}
                        />
                    </Form.Item>
                </div>
                <Divider/>
                <Flex justify={"flex-end"}>
                    <Button
                        htmlType={"submit"}
                        type={"primary"}
                        loading={updatingSettings}
                        style={{width: 200}}
                    >
                        Save
                    </Button>
                </Flex>
            </Form>
            : <Result title={"Access denied!"} status={"error"}/>
        }

    </Card>
}

export default TestSettings;
