import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Badge, Button, Checkbox, message, Progress, Space, Table, Tooltip, Typography} from "antd";
import {getRepresentativesReport, getRepresentativesReportExcel} from "@API/supervision";

import "../Supervision.less";
import Localizator from "@global/Localizator";
import {
    CheckOutlined,
    CheckSquareOutlined,
    FileExcelOutlined,
    TeamOutlined,
    WalletOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {green, red} from '@ant-design/colors';
import ValueFormatter from "@global/ValueFormatter";
import ClientActions from "@components/supervision/actions/ClientActions";
import RepresentativeProblemClients from "@components/supervision/indicators/RepresentativeProblemClients";
import {SupervisionContext} from "@components/supervision/contexts/SupervisionContext";
import ConfigSelector from "../global_configs/ConfigSelector";
import {downloadFile} from "@global/FileDownloader";

const green_color = green[6]

const RepresentativesReport = () => {
    const [columns, setColumns] = useState([])
    const [reportLoading, setReportLoading] = useState(false);
    const [data, setData] = useState({data: {children: [], problem_counters: {}, total_counters: {}}})
    const [config, setConfig] = useState(null);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [useClientConfig, setUseClientConfig] = useState(true);
    const abortControllerRef = useRef(new AbortController());

    const prepareClientTitle = useCallback((client) => {
        let configUrlParams = "";
        if (config)
            configUrlParams = `?${new URLSearchParams({configId: config.id, useClientConfig: useClientConfig})}`;
        let title = <Space direction={'vertical'} size={0}>
            <Link className={'flag-client-link'}
                  to={"../client/" + client.client_id + configUrlParams}
                  relative="path" target={'_blank'}
            >
                <TeamOutlined/>
                {client.client_name}
            </Link>
            <ClientActions client={client} actions={['mute_client', 'analyze', 'proposal']} size={'small'}
                           showTitle={true}/>
        </Space>
        return title
    }, [config, useClientConfig])

    const staticColumns = useMemo(() => [{
        title: <>{'Name'}</>,
        dataIndex: 'name',
        sorter: (a, b) => a.representative_name?.localeCompare(b.representative_name),
        key: 'entity_title',
        width: '35%',
        render: (value, record) => {
            let level = record['level']
            switch (level) {
                case 'client':
                    return prepareClientTitle(record)
                case 'representative':
                    return <Typography.Text><WalletOutlined/> {record['representative_name']}</Typography.Text>
            }
        },
    }, {
        title: 'Wealth health',
        dataIndex: 'red_flag_clients_percent',
        key: 'red_flag_clients_percent',
        align: 'left',
        width: '100',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
            let aValue = 1 - (a['problem_counters']['clients'] / a['total_counters']['clients'])
            let bValue = 1 - (b['problem_counters']['clients'] / b['total_counters']['clients'])
            return aValue - bValue
        },
        render: (value, record) => {
            let level = record['level']
            switch (level) {
                case 'client':
                    return null
                case 'representative':
                    let clientsTotal = record['total_counters']['clients']
                    let clientsProblem = record['problem_counters']['clients']
                    let flagsProblem = record['problem_counters']['flags']
                    let percent = ValueFormatter.round((1 - (clientsProblem / clientsTotal)) * 100, 1)
                    let tipText = `${clientsProblem} clients of ${clientsTotal} have warnings, ${flagsProblem} warnings raised in total`

                    let color = green_color
                    return <Tooltip title={tipText}>
                        <Progress percent={percent} steps={20} size="small" strokeColor={color}/>
                    </Tooltip>
            }
        },
    },], [prepareClientTitle])

    const loadData = (configId, _useClientConfig) => {
        setReportLoading(true);

        getRepresentativesReport(configId, _useClientConfig, (data, error) => {
            setReportLoading(false);
            if (error) {
                console.error(data)
                message.error("Error while retrieving representatives report");
                return
            }
            //console.table(data.data)

            let curColumns = [...staticColumns]
            const meta = data.metadata
            //sums by flag type
            let reprRows = data['data']['children']
            const summary = {}

            for (let col of meta.flags_order) {
                let flagTitle = meta.flag_labels[col]

                if (!summary.hasOwnProperty(col)) {
                    summary[col] = 0
                }
                let icon = Localizator.t('supervision.flag.icon.' + col)
                if (typeof icon == 'string') {
                    icon = <WarningOutlined/>
                }
                let titleTip = <Tooltip title={flagTitle} color={'red'}>{icon}</Tooltip>


                curColumns.push({
                    title: titleTip,
                    dataIndex: ['flags', col, 'problem_counters', 'clients'],
                    align: 'center',
                    width: 30,
                    render: (value, record) => {
                        let level = record['level']
                        if (level == 'representative') {
                            return <RepresentativeProblemClients
                                badClientsCount={value}
                                allClientsCount={record['total_counters']['clients']}
                                flagTitle={flagTitle}/>
                        } else {
                            let field_value_bool = record['flags'][col]['raised']
                            let hasCustomSettings = (record['flags'][col]['has_client_limit'] || record['flags'][col]['flag_muted'])
                            if (field_value_bool) {
                                let status = hasCustomSettings ? 'warning' : 'error'
                                let ttl = flagTitle
                                if (hasCustomSettings && !useClientConfig) {
                                    ttl += ' check failed (client custom settings ignored).'
                                } else if (hasCustomSettings) {
                                    ttl += ' check failed (client custom settings applied).'
                                }
                                return <Tooltip title={ttl} color={red[5]}>
                                    <Badge status={status} style={{cursor: 'pointer'}}
                                           onClick={() => {
                                               console.info("table badge clicked")
                                           }}/>
                                </Tooltip>
                            } else {
                                if (hasCustomSettings) {
                                    return <CheckSquareOutlined style={{color: green_color, opacity: 0.5}}/>
                                }
                                return <CheckOutlined style={{color: green_color, opacity: 0.5}}/>
                            }
                        }
                    }
                })
            }

            setColumns(curColumns)
            setData(data);
        }, abortControllerRef.current.signal)
    }

    const loadDataWithValues = useCallback(() => {
        if (config) {
            loadData(config.id, useClientConfig);
        }
    }, [config, useClientConfig])

    const downloadRepresenativesReport = () => {
        if (config) {
            setLoadingExcel(true);
            getRepresentativesReportExcel(config.id, useClientConfig, (response, error) => {
                if (error) {
                    message.error("Error while downloading representatives report excel");
                    setLoadingExcel(false);
                    return
                }
                let file_type = response.headers['content-type'];
                let file_name = `SPAS Supervision Representatives Report (${new Date().toLocaleString()}).xlsx`;
                downloadFile(response.data, file_name, file_type, () => {
                    setLoadingExcel(false);
                })
            }, abortControllerRef.current.signal)
        }
    }

    useEffect(() => {
        return () => abortControllerRef.current.abort()
    }, [])

    useEffect(() => {
        if (config !== null && useClientConfig !== null) {
            loadData(config.id, useClientConfig);
        }
    }, [config, useClientConfig])

    // useEffect(() => {
    //     if (config) {
    //         setUseClientConfig(config.use_client_config);
    //     }
    // }, [config])

    return (
        <div className="supervision-container">
            <Space direction={'horizontal'} style={{marginBottom: 24}} size={15}>
                <div style={{width: 300}}>
                    <ConfigSelector setSelectedConfig={setConfig}/>
                </div>
                <Checkbox checked={useClientConfig} onChange={e => setUseClientConfig(e.target.checked)}>
                    Use clients' limits
                </Checkbox>
                <Button type="primary" disabled={config === null} loading={loadingExcel}
                        onClick={() => downloadRepresenativesReport()}
                >
                    <FileExcelOutlined/> Download report
                </Button>
            </Space>
            <Typography.Title level={4} className="spas">
                Representatives warnings
            </Typography.Title>
            <Typography.Paragraph>{Localizator.t('supervision.description.representatives')}
            </Typography.Paragraph>
            {data && <SupervisionContext.Provider value={{
                updateData: loadDataWithValues
            }}>
                <Table
                    className={'representatives-tree'}
                    rowClassName={'representatives-tree'}
                    style={{width: '100%'}}
                    columns={columns}
                    loading={reportLoading}
                    dataSource={data.data.children}
                    expandRowByClick={true}
                    bordered={false}
                    pagination={{pageSize: 25, hideOnSinglePage: true}}
                    // scroll={{ y: 240 }}
                    size={"small"}
                    rowKey={record => record.level + record.representative_name + record.client_id}
                    summary={(pageData) => {
                        if (!data || !data.metadata) {
                            return null
                        }

                        let summary = {}

                        for (let representative of data['data']['children']) {
                            for (let flag_label of data['metadata']['flags_order']) {
                                if (!summary.hasOwnProperty(flag_label)) {
                                    summary[flag_label] = 0
                                }
                                let value = representative['flags'][flag_label]['problem_counters']['clients']
                                summary[flag_label] += value
                            }
                        }
                        let totalRFClients = data['data']['problem_counters']['clients']
                        let totalRFs = data['data']['problem_counters']['flags']
                        let allClients = data['data']['total_counters']['clients']
                        let totalWealthHealth = totalRFClients / allClients
                        let percent = ValueFormatter.round((1 - totalWealthHealth) * 100, 1)
                        let tipText = `${totalRFClients} clients of ${allClients} have warnings, ${totalRFs} warnings raised in total`

                        let color = green_color

                        return <>
                            <Table.Summary.Row style={{background: '#E0AD60', fontSize: 12}}>
                                <Table.Summary.Cell index={0} align={'right'}>Total</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <Tooltip title={tipText}>
                                        <Progress percent={percent} steps={20} size="small" strokeColor={color}/>
                                    </Tooltip>
                                </Table.Summary.Cell>
                                {data['metadata']['flags_order'].map(col_name => {
                                    return <Table.Summary.Cell align={'center'}>{summary[col_name]}</Table.Summary.Cell>
                                })

                                }
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={5}>
                                    <Typography.Text type="danger">{totalRFs}</Typography.Text> warnings raised
                                    for <Typography.Text
                                    type="danger">{totalRFClients}</Typography.Text> of {allClients} total clients.
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    }}
                />
            </SupervisionContext.Provider>}
            {/*<Typography.Title level={4} className="spas">
                Chart
            </Typography.Title>
            <AdvisorWarnings advisor_rows={data} metadata={metadata}/>*/}
        </div>
    )
}

export default RepresentativesReport;
