import React, {createContext, useContext, useEffect, useState} from "react";
import {auth, cancel, logout as authLogout, recognition} from "@API/auth";
import ReactGA from "react-ga4";


const authContext = createContext();


const AuthWrapper = ({children}) => {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
};


const useAuth = () => {
    return useContext(authContext)
};


const getAccessDetailsStoreSnapshotMemoized = () => {
    let cachedStr = null;
    let cachedObj = null;
    return function () {
        const valueStr = sessionStorage.getItem("access-details");
        if (valueStr === cachedStr) {
            return cachedObj;
        } else {
            cachedStr = valueStr;
            cachedObj = JSON.parse(valueStr);
            return cachedObj;
        }
    }
}


const subscribeAccessDetailsStore = (listener) => {
    window.addEventListener("storage", listener);
    return () => void window.removeEventListener("storage", listener);
}


const useProvideAuth = () => {
    const [user, setUser] = useState(null);
    const [globalValues, setGlobalValues] = useState(null);
    const [notifications, setNotifications] = useState([])
    const [authLoading, setAuthLoading] = useState(true);
    const [proposalListChange, setProposalListChange] = useState(0);
    const accessDetails = React.useSyncExternalStore(
        subscribeAccessDetailsStore,
        getAccessDetailsStoreSnapshotMemoized()
    );

    useEffect(() => {
        console.log("accessDetails", accessDetails);
    }, [accessDetails])

    const logout = () => {
        authLogout();
    }

    const refreshAuth = () => {
        setAuthLoading(true);
        auth((user) => {
            setGlobalValues(user?.meta?.app_settings?.GLOBALS)
            if (!user.picture) {
                user.picture = '/img/default-user.png'
            }

            if (user?.meta?.notifications) {
                setNotifications(user.meta.notifications)
            } else {
                setNotifications([])
            }
            setUser(user)
        }, () => {
            setAuthLoading(false)
        });

        return () => {
            cancel && cancel();
        }
    }

    const isAdmin = () => {
        if (user?.roles) {
            return user.roles.includes('admin')
        }
        return false
    }

    const isSales = () => {
        if (user?.roles) {
            return user.roles.includes('sales')
        }
        return false
    }

    const isHead = () => {
        if (user?.roles) {
            return (user.roles.includes('head') || isAdmin())
        }
        return false
    }

    const advisorAllowed = () => {
        if (user?.roles) {
            return (user.roles.includes('advisor'))
        }
        return false
    }

    const retailAllowed = () => {
        if (user?.roles) {
            return (user.roles.includes('retail') || advisorAllowed())
        }
        return false
    }

    const recognized = () => {
        return (advisorAllowed() || retailAllowed())
    }

    const isSupervisor = () => {
        if (user?.roles) {
            return user.roles.includes('supervisor')
        }
        return false
    }

    const orionEnabled = () => {
        return user?.meta?.app_settings?.ORION_ENABLED === true
    }

    const authType = () => {
        return user?.meta?.app_settings?.AUTH_TYPE
    }

    const isDataLocked = (key) => {
        return (accessDetails?.locked_data ?? []).includes(key);
    }

    const isActionLocked = (key) => {
        return (accessDetails?.locked_actions ?? []).includes(key);
    }

    const requestLockInfo = (key) => {
        const info = {locked: false, until: null}
        if ((accessDetails?.locked_requests ?? {}).hasOwnProperty(key)) {
            info.locked = true;
            info.until = accessDetails?.locked_requests[key]
        }
        return info;
    }

    const getDataLimit = (key) => {
        const limits = accessDetails?.data_limits ?? {};
        return limits[key];
    }

    const sendGAEvent = (options) => {
        options.subscription = user.current_subscription?.plan?.id
        ReactGA.send({
            hitType: "pageview",
            title: "Event",
            ...options
        });
        console.log('Event sending')
    }

    useEffect(() => {
        refreshAuth()
    }, [])

    return {
        user,
        notifications,
        globalValues,
        isSales,
        isAdmin,
        isHead,
        isSupervisor,
        orionEnabled,
        advisorAllowed,
        retailAllowed,
        recognized,
        authLoading,
        authType,
        refreshAuth,
        logout,
        proposalListChange,
        setProposalListChange,
        accessDetails,
        isDataLocked,
        isActionLocked,
        requestLockInfo,
        getDataLimit,
        sendGAEvent
    }
}


export {AuthWrapper, useAuth}
